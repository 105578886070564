import React, {  useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MoonLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';

const SubscriptionForm = ({email,pkg_code}) => {
  
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isDisabled, setIsDisabled] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

 


  async function subscripe  ({paymentMethod}){
let data = JSON.stringify({
  "name": 'user',
  "email": email,
  "paymentMethod": paymentMethod.id,
  "priceId": pkg_code,
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.cellercertified.com/subscribe',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};

axios.request(config)
.then((response) => {
 const x =  response.data;
 
 if(x.status){
  setPaymentComplete(true)
  setIsDisabled(false)
  navigate('/subthankyou');
 }
 else{
  setPaymentComplete(true)
  toast.error(x.msg)
 }
 
 console.log(x);
})
.catch((error) => {
  
  setPaymentComplete(true)
  console.log(error);
  toast.error("network error")
});

  }
  const handleSubmit = async (event) => {
    console.log("handling pay")
    setIsDisabled(true)
    event.preventDefault();

    if (!stripe || !elements) {
      console.log('stripe not yet loaded')
      return;
    }

   
    const  paymentMethod  = await stripe?.createPaymentMethod({
      type: 'card',
      card: elements?.getElement(CardElement),
    });

          
      try { 
        const x = await subscripe(paymentMethod)
       

      }catch(e) {
        setIsDisabled(false)
        toast.error('unvalid response'); console.log(e.message)}


    
  };

  return (
    <form 
    
    className=' p-8 rounded-lg shadow-lg '
    onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
      <div style={{ marginBottom: '20px' }}>

        <label style={{ display: 'block', marginBottom: '10px', fontWeight: 'bold' }}>
          Card details
        </label>
        <CardElement />
      </div>
      <button
      disabled={isDisabled}
        type="submit"
        style={{
          padding: '10px 20px',
          backgroundColor: '#0070f3',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Pay
      </button>
      {(isDisabled && !paymentComplete)&&
        <div className="d flex items-center pt-4">
    <MoonLoader size={18} className="mx-2"/>
    <h1>Verifying Payment</h1>
    </div>}

    </form>
  );
};

export default SubscriptionForm;
