import logo2 from "../images/logo_2.2.jpg";
import QRCode from 'qrcode.react';
function INFO_TOP_BATCH({ID,Date,link,Upload,fun,Download}) {
  console.log(Date)
  return (
    <div  className="border-b INFO_TOP_CNT flex sm:flex-row flex-col pb-2 w-[100%] items-center gap-y-2 sm:justify-between ">
      <div className="flex items-center w-75  sm:w-[40vw]">
        <img src={logo2} alt="logo2" className="w-[55%] h-[55%]"/>
      </div>
      <div className="flex flex-row items-center justify-center sm:justify-end  w-[60vw]">
        <div className=" " >
          <QRCode value={link}  style={{width:60,height:60}}/>
        </div>

        <div className="px-3 md:px-1 text-xs md:text-[1.2vw] lg:text-xs ">
       
          <div className=" mb-0 font-medium  text-sky-500  hover:cursor-pointer"  onClick={()=>{window.print()}}>
            Print this link
          </div>
          <div className="flex gap-x-2  mb-0 font-medium  text-sky-500  hover:cursor-pointer" >
            <div onClick={handleButtonClick}>Share this link</div>  <div className="border-l-2 px-2"><button variant="contained" onClick={Download}> Download</button></div> 
          </div>
          <div className=" mb-0 text-slate-900 font-medium w-max"> ID: {ID}</div>
          <div className="  text-slate-900 font-medium w-max">Date: {Date}
          </div>
        </div>
      </div>
    </div>
  );
}

const handleButtonClick = () => {
    
  const myDiv = document.getElementById('main_share');
  if (myDiv) {
    myDiv.hidden =false;
  }
};
export default INFO_TOP_BATCH;
