import React, { useEffect, useState } from 'react';
import TOOLBAR from './toolbar';
import InnerHeader from './InnerHeader';
import { apiCall } from '../utils/apiCall';
const MobileDeviceCertification = () => {
    const [MobileDeviceCertificationText,setMobileDeviceCertificationText] = useState('')

    useEffect(() => {
        fetchData();
    }, []); 
      const fetchData = async () => {
        const url = "https://api.cellercertified.com/get-footer-texts"
        try {
            const response = await apiCall(url,'GET')
             console.log(response)
            if (response?.status == true) {
                setMobileDeviceCertificationText(response?.texts?.MobileDeviceCertification);
                 
            } else {
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div>
        <TOOLBAR/> 
        <InnerHeader 
        t1={"Default Text"}
        t1_1={""}
        t1_2={""}
        t1_3={""}
        t2={"Default Text"}      
        />
        <div className="inner-area py-10 container lg:max-w-screen-lg mx-auto px-5">
           <h1 className='text-[1.4rem] md:text-[2.4rem] font-bold'>Mobile Device Certification</h1>
       {/* <p>{MobileDeviceCertificationText}</p> */}
       <div dangerouslySetInnerHTML={{ __html: MobileDeviceCertificationText }} />
        </div>
       </div>
    );
}

export default MobileDeviceCertification;
