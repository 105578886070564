import logo_3 from "../images/Logo_3.png";
function DEVICE_STATUS({ a, b, c, d, e, f, REF,g,h,i, j, k, l, m , n }) {
  return (
    <div  className=" rounded-lg my-10 p-5 px-1 sm:px-5 shadow-xl">
      <div className="flex  items-center">
        <img src={logo_3} alt={"logo_3"} className="mr-3" />
        <h1 className="text-black font-bold text-2xl  ">Device Status</h1>
      </div>
      <div  className="mt-3">
      { a && <ITEM_1 t={"Blacklist Status"} v={a} reason={`${k}`}/>}
      { b && <ITEM_2 REF={REF} t={"iCloud Lock"} v={b} reason={''}/>}
      { c && <ITEM_1 t={"Date Sold"} v={c} reason={''}/>}
      { d &&  <ITEM_2 t={"Warranty"} v={d} reason={''}/>}
      { e &&  <ITEM_1 t={"Loaner Device"} v={e} reason={''} />}
      { f && <ITEM_2 t={"Refurbished Device"} v={f} reason={''} />}
      { g && <ITEM_1 t={"Demo Unit"} v={g} reason={''}/>}
      { h && <ITEM_2 t={"Replaced Device"} v={h} reason={''}/>}
      { i && <ITEM_1 t={"Replacement Device"} v={i} reason={''}/>}
      { j && <ITEM_1 t={"ESN STATUS"} v={j} reason={''}/>}
      { l && <ITEM_1 t={"BLACKLISTED BY"} v={l} reason={''}/>}
      { m && <ITEM_2 t={"BLACKLISTED COUNTRY"} v={m} reason={''}/>}
      { n && <ITEM_1 t={"BLACKLISTED ON"} v={n} reason={''}/>}
      </div>
    </div>
  );
}

function ITEM_1({ t, v,reason }) {
  return (
    <div className="bg-gray-100 flex text-black border-b">
      <div className="w-2/3 p-2">
        <div className="text-base">{t}</div>
      </div>
      <div className="w-1/2 p-2 ">
        <div className={`      
        text-base 
        ${v=== 'ON' ? 'text-free-500':''} 
        ${v=== 'OFF' ? 'text-green-500':''} 
        ${v=== 'NO' ? 'text-green-500':''} 
        ${v=== 'YES' ? 'text-red-500':''} 
        ${v=== 'OUT OF WARRANTY' ? 'text-red-500':''}
        ${v=== 'ELIGIBLE' ? 'text-green-500':''}
        ${v=== 'LIMITED WARRANTY' ? '#ffcc00':''}
        ${v=== 'ACTIVE' ? 'text-green-500':''}
        ${v=== 'ACTIVE WARRANTY' ? 'text-green-500':''}
        ${v=== 'CLEAN' ? 'text-green-500':''}
        ${v=== 'BLACKLIST' ? 'text-red-500':''}
        ${v=== 'BLACKLISTED' ? 'text-red-500':''}
        ${v=== 'WARRANTY EXPIRED' ? 'text-red-500':''}
        ${v=== 'COVERAGE EXPIRED' ? 'text-red-500':''}
        ${v=== 'EXPIRED' ? 'text-red-500':''}
        ${v=== 'BLOCKED' ? 'text-red-500':''}
        ${t=== 'BLACKLISTED BY' ? 'text-red-500':''}
        ${t=== 'BLACKLISTED ON' ? 'text-red-500':''}
        ${t=== 'BLACKLISTED COUNTRY' ? 'text-red-500':''}
        `}>{v} {(v !=='CLEAN')&&reason}</div>
      </div>
    </div>
  );
}
function ITEM_2({ t, v, REF }) {
  return (
    <div ref={REF} className="flex text-black border-b">
      <div className="w-2/3 p-2  ">
        <div className="text-base">{t}</div>
      </div>
      <div className="w-1/2 p-2 ">
        <div className={`text-base 
        ${v=== 'ON' ? 'text-red-500':''} 
        ${v=== 'OFF' ? 'text-green-500':''} 
        ${v=== 'NO' ? 'text-green-500':''} 
        ${v=== 'YES' ? 'text-red-500':''} 
        ${v=== 'OUT OF WARRANTY' ? 'text-red-500':''}
        ${v=== 'ELIGIBLE' ? 'text-green-500':''}
        ${v=== 'LIMITED WARRANTY' ? '#ffcc00':''}
        ${v=== 'ACTIVE' ? 'text-green-500':''}
        ${v=== 'ACTIVE WARRANTY' ? 'text-green-500':''}
        ${v=== 'CLEAN' ? 'text-green-500':''}
        ${v=== 'BLACKLISTED' ? 'text-red-500':''}
        ${v=== 'BLACKLIST' ? 'text-red-500':''}
        ${v=== 'WARRANTY EXPIRED' ? 'text-red-500':''}
        ${v=== 'COVERAGE EXPIRED' ? 'text-red-500':''}
        ${v=== 'EXPIRED' ? 'text-red-500':''}
        ${v=== 'BLOCKED' ? 'text-red-500':''}
        ${t=== 'BLACKLISTED BY' ? 'text-red-500':''}
        ${t=== 'BLACKLISTED ON' ? 'text-red-500':''}
        ${t=== 'BLACKLISTED COUNTRY' ? 'text-red-500':''}
        `}>{v}</div>
      </div>
    </div>
  );
}
export default DEVICE_STATUS;
