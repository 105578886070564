import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/Stripe_Checkout_Form";
const stripePromise = loadStripe("pk_test_51NryWTKfQ1sQ4OinftW73z10043TnzxQR3YAtMmjwLXYg9EKgzpVeszdaxkbnGBonCzqhpblQKFYouuYcDc2C4iH00dAFxTptF");

const STRIPE_PAY = ({IMEI,EMAIL})=> {
        const [clientSecret, setClientSecret] = useState("");

        useEffect(() => {
            if(!IMEI){ return;}
          // Create PaymentIntent as soon as the page loads
          fetch("https://api.cellercertified.com/create-payment-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ IMEI,EMAIL}),
          })
            .then((res) => res.json())
            .then((data) => {setClientSecret(data.clientSecret);});
            
        }, [IMEI]);
      
        const appearance = {
          theme: 'flat',
        };
        const options = {
          clientSecret,
          appearance,

        };

      
        return (
          <div className="flex items-center justify-center m-0 p-0">

          <div>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            )}
            </div>
          </div>
        );
}

export default STRIPE_PAY;