import { Link } from "react-router-dom";

function DROPDOWN() {
  return (
    <div className=" fixed z-30 w-full rounded-md bg-white top-16 right-0 shadow-2xl text-center block">
            <h1 className="text-sm font-semibold p-2 bg-sky-400  text-white py-5 border-b-1 ">
            <Link to="/pricing" className="text-sm p-2">
        PRICING
      </Link>
            </h1>
            <h1 className="text-sm font-semibold p-2 bg-sky-400  text-white py-5 border-b-1 border-white">
            <Link to="/faq" className="text-sm p-2">
        FAQS
      </Link>
            </h1>
            <h1 className="text-sm font-semibold p-2 bg-sky-400  text-white py-5">
            <Link to="/login" className="text-sm p-2">
        LOGIN
      </Link>
            </h1>


      

      <h1 className="text-sm font-semibold p-2 bg-sky-400  text-white py-5">
      <Link to="/" className="text-sm p-2">
      RUN A REPORT NOW
      </Link>

      </h1>
    </div>
  );
}

export default DROPDOWN;
