import {LOGO1} from "../SVGs";
import PuffLoader from "react-spinners/PuffLoader";
import { useEffect, useState } from "react";


function LOADER() {


    const [loadingtext, setText] = useState('...');    
    const texts  = ['Just a minute..','Working our magic','Keeping you safe and Certified','Checking databases worldwide','Checking Device Info','Generating Your Report']
    const [text_no,settext_no] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        settext_no((text_no+1)%texts.length)
        setText(texts[text_no]);
      }, 3000);
  
      return () => clearInterval(interval);
    }, [text_no]);


    return ( 

        <div
        id="black"
        className="w-full h-full z-50 fixed  items-center justify-center flex flex-col"
        style={{ backgroundColor: "white" }}
      >
      <PuffLoader
        color={'skyblue'}
        loading={true}
        size={300}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{position: "absolute"}}
      />
      
      
      <LOGO1 h={53}/>
       <h1>{loadingtext}</h1>
        </div>
     );
}

export default LOADER;
