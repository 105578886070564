import React, { useEffect, useState } from 'react';
import TOOLBAR from './toolbar';
import InnerHeader from './InnerHeader';
import { apiCall } from '../utils/apiCall';

const Datacollection = () => {
    const [DataCollectionText,setDataCollectionText] = useState('')

    useEffect(() => {
        fetchData();
    }, []); 
      const fetchData = async () => {
        const url = "https://api.cellercertified.com/get-footer-texts"
        try {
            const response = await apiCall(url,'GET')
             console.log(response)
            if (response?.status == true) {
                setDataCollectionText(response?.texts?.DataCollection);
                 
            } else {
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (        
        <div>
         <TOOLBAR/>
         <InnerHeader 
        t1={"Default Text"}
        t1_1={""}
        t1_2={""}
        t1_3={""}
        t2={"Default Text"}      
        />
         <div className="inner-area py-10 container lg:max-w-screen-lg mx-auto px-5">
            <h1 className='text-[1.4rem] md:text-[2.4rem] font-bold'>Data Collection</h1>
            <div dangerouslySetInnerHTML={{ __html: DataCollectionText }} />
        {/* <p>{DataCollectionText}</p> */}
         </div>
        </div>
    );
}

export default Datacollection;
