import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import "../Views/stripe.css"
export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if (!stripe) { return;}
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret) {return;}

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
            console.log("Payment succeeded!")
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://www.cellercertified.com/Middleware",
      },
    });
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    paymentMethodTypes: ['card'],
    layout: "tabs",
  }

  return (
    <form id="payment-form" className="w-[100%] px-[20px] sm:px-[40px] md:px-[40px] lg:px-[40px] xl:px-[40px] 2xl:px-[40px]" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
       onChange={(e) => {setEmail(e.value)}}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions}  />

      <h1 className='text-black px-8  items-center text-center'>
      If you are selling this device we recommend <a href="https://support.apple.com/en-us/HT201351" className="underline font-semibold" >disabling iCloud</a> first to ensure a clean report.
      </h1>

      <button className="btn my-[20px]" disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}