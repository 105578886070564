import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Pricing.scss";
import ChoosePlan from "./ChoosePlan";
import PricingPlans from "./PricingPlans";

const PricingTabs = () => {
  const [currentstate, setcurrentstate] = useState();

  function handleClick() {
    setcurrentstate(true);
  }

  return (
    <>
      <div className="topcontentt mt-5 pt-12">
        <h1>CHOOSE THE PLAN THAT’S RIGHT FOR YOU</h1>
        <p>
          Easy
          <span> Straight-Forward </span>
          Pricing. No annual
          <span> contracts, ever.</span>
        </p>
      </div>
      <div className="flex topTwoBox ">
        <NavLink
          to="/payasyougo"
          // onClick={handleClick}
          className={({ isActive }) =>
            isActive ? "box" : "border-2  border-grey-500 rounded-lg p-1"
          }
        >
          <h1>Pay As You Go</h1>
          <p>Buy What You Need, When You Need.</p>
        </NavLink>
        <NavLink
          to="/pricing"
          className={({ isActive }) =>
            isActive ? "box" : "border-2  border-grey-500 rounded-lg p-1"
          }
        >
          <h1>Monthly Subscription</h1>
          <p>Lock In Deep Discounts and Extra Features</p>
        </NavLink>
      </div>
      {/* {currentstate && <ChoosePlan />} */}
    </>
  );
};

export default PricingTabs;
