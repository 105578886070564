import { LOGO1 } from "../SVGs";
import PuffLoader from "react-spinners/PuffLoader";
import bg from "../images/bg.jpg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import STRIPE_PAY from "../Views/stripe_payment";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

function Unregistered() {
  let location = useLocation();
  let navigate = useNavigate();
  const [IMEI, setIMEI] = useState();
  const [Model, setModel] = useState();

  const [loadingtext, setloadingtext] = useState("Loading...");

  useEffect(() => {
    try {
      setIMEI(location.state.IMEI);
    } catch (e) {
      navigate("/");
      return;
    }

    const interval = setInterval(() => {
      const loading_div = document.getElementById("black");
      loading_div.classList.add("hidden");
    }, 2000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (IMEI === undefined) {
      return;
    }
    fetch(`https://api.cellercertified.com/IMEI_DATA/${IMEI}`)
      .then((res) =>
        res.json().then((data) => {
          setModel(data.data.Devices);
        })
      )
      .catch((e) => {
        console.log("ok");
      });
  }, [IMEI]);


  return (
    <>
      <LOADING loadingtext={loadingtext} />
      <div className="App h-fit ">
        <img
          src={bg}
          alt="bg img"
          className="absolute w-full h-96 print:hidden"
        />
        <div
          className="bg-white flex 
        
        w-[95%]
        sm:w-[70%]
        md:w-[70%]
        lg:w-[80%]
        xl:w-[65%]  
        sm:justify-evenly lg:justify-between
        s:flex-row
        lg:flex-row
        flex-col        
        p-0
        sm:p-10
        md:p-10
        lg:p-10
        xl:p-10
        2xl:p-10
        shadow-lg z-10 mx-auto mt-28 mb-10"
        >
          <div className="m-10">
            <div className="flex items-center">
              <div
                className="
          w-fit
          px-6
            py-2
          bg-green-200
          rounded-lg
          text-green-500
          font-semibold
          text-[25px]
          "
              >
                <h1> CERTIFIED</h1>
              </div>
              <h1 className="ml-2 text-xl md:text-3xl "> {Model} </h1>
            </div>
            <div className="text-4xl  md:text-6xl lg:text-6xl font-semibold mb-4">
              <h1 className="lg:ml-0 ml-2 mt-5  ">Your History</h1>
              <h1 className="lg:ml-0 ml-2 mb-4">Report is ready!</h1>
            </div>

            <div className=" md:w-[100%] lg:w-[400px] flex-wrap text-black m-2 border-[1px] rounded-lg bg-gray-300 p-2 flex flex-col">
              <h1>
                Buy & Sell With Confidence For Top Dollar Remember, every Celler
                Certified Device History Report Checks For:
                <strong>{IMEI}</strong>
              </h1>
            </div>

            <div className="mb-10 mr-4">
              <ITEM text="Reported Stolen or lost?" />
              <ITEM text="Eligible to activate?" />
              <ITEM text="Locked to previous owner?" />
              <ITEM text="Locked to a carrier?" />
              <ITEM text="And More !" />
            </div>
          </div>

          {/* Right Card -> Card information */}
          <div className="border-[1.5px] w-[100%] lg:w-[40%]  pt-5 rounded-xl shadow-2xl border-gray-400">
            <div className="text-black text-center items-center justify-center flex flex-col px-5">
              <h1 className="border-b-[1px] pb-4 text-2xl">
                View your Device History Report now!
              </h1>
              <h1 className="pt-4  px-2  text-3xl">
                {" "}
                Know the true value of this device for only
              </h1>
              <h1 className=" text-3xl w-fit font-bold border-[1px] rounded-lg bg-gray-300 px-3 py-1 m-2">
                $1.99
              </h1>
            </div>

            <STRIPE_PAY IMEI={IMEI} />

            <div className="flex flex-col justify-center items-center text-sm px-5"></div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}

function ITEM({ text }) {
  return (
    <div
      className="
    p-2
    mt-5
    mb-5
    border-t-[1px]
    text-2xl
    font-semibold
    text-gray-500
    flex
    items-center
    "
    >
      <div className="mr-4">
        <LOGO1 w={25} />
      </div>
      {text}
    </div>
  );
}

function CUSTOM_INPUT({ Placeholder_txt }) {
  return (
    <input
      className="
        border-[1.5px]
        mx-2
        my-4
        shadow-inner
        text-lg
        px-3
        py-1
        text-black
        "
      placeholder={Placeholder_txt}
    ></input>
  );
}

function LOADING({ loadingtext }) {
  return (
    <div
      id="black"
      className="w-full h-full z-50 fixed  items-center justify-center flex flex-col"
      style={{ backgroundColor: "white" }}
    >
      <PuffLoader
        color={"skyblue"}
        loading={true}
        size={300}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{ position: "absolute" }}
      />

      <LOGO1 h={53} />
      <h1>{loadingtext}</h1>
    </div>
  );
}

export default Unregistered;
