import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Thank_You_Logo from '../images/thankyou_logo.svg';

function THANKYOU() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const uid = searchParams.get('uid');
  const imei = searchParams.get('imei');
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const sendEmail = async () => {
    try {
      if (!email || !uid || !imei) {
        throw new Error('Missing required parameters');
      }

      const api2 = "https://api.cellercertified.com/send-report-email";
      setIsSendingEmail(true); // Show loading state

      const response = await axios.post(
        api2,
        {
          userEmail: email,
          reportLink: `https://www.cellercertified.com/publicreport/?UID=${uid}`,
          deviceImei: imei,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success('Email sent successfully');
      } else {
        toast.error('Email sending failed');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      toast.error('Email sending failed');
    } finally {
      setIsSendingEmail(false); // Hide loading state
    }
  };

  useEffect(() => {
    sendEmail();
  }, []); // No dependencies, runs once

  return (
    <div className="flex flex-col items-center justify-center h-[100vh]">
      {/* Loading state */}
      {isSendingEmail && <p>Sending email...</p>}

      {/* Success message */}
      {!isSendingEmail && (
        <div className="justify-center text-center w-[350px] mb-10">
          <h1 className="text-sky-500 font-bold text-3xl">Thank You!</h1>
          <p className="text-gray-700 text-lg">Your payment was successful.</p>
          <p className="text-gray-700 text-lg">
            A confirmation email with your Device History Report has been sent to you.
          </p>
        </div>
      )}

      <div>
        <div className='border rounded-lg shadow-lg px-6 py-8 sm:w-[430px] w-[350px] pt-12'>
          <div className='absolute '>
            <img className=' relative -top-20 sm:left-40 left-32 w-[60px]' alt='thank you logo' src={Thank_You_Logo} ></img>
          </div>
          <div className='flex border-b py-2'><strong className=' w-28'>Order Details</strong><h1 className='text-gray-600 font-thin'>Single Check</h1></div>
          <div className='flex border-b py-2'><strong className=' w-28'>Customer</strong><h1 className='text-gray-600 font-thin'>{email}</h1></div>
          <div className='flex border-b py-2'><strong className=' w-28'>Report IMEI</strong><h1 className='text-gray-600 font-thin'>{imei}</h1></div>
          <div className='flex border-b py-5'><a className='btn bg-[#00A651] p-4 w-full text-center font-bold' href={`https://www.cellercertified.com/publicreport/?UID=${uid}`} >View Your Device History Report</a></div>
          <div className='flex p-1 justify-center'><a href='/' className='my-1 font-bold text-sky-500'>Purchase Another Report</a></div>
        </div>
      </div>
    </div>
  );
}

export default THANKYOU;
