import "./Pricing.scss";
import PricingPlans from "./PricingPlans";
const Pricing = () => {
  return (
    <div className="pagebody">
      <div className="pricing">
        <div className="top">
          <PricingPlans/>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
