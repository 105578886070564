import logo_3 from "../images/Logo_3.png";
import img1 from "../images/lock.png";
import img2 from "../images/AT&T.png";
import img3 from "../images/Tmobile.png";
import img4 from "../images/verzion.png";

function CARRIER_DETAILS({ REF,v0,v1,v2,v3,v4,Possible_Carrier }) {
  if(v4==='BLACKLISTED' ||  (v4 && v4.includes('REPORTED STOLEN OR LOST')) ){
    v1 = 'NOT Eligible for Activation';
    v2 = 'NOT Eligible for Activation';
    v3 = 'NOT Eligible for Activation';
    v0 = 'BLACKLISTED'
  }


  if (typeof v0 === 'undefined' && Possible_Carrier && Possible_Carrier.includes('UNLOCKED')) {
    v0 = 'UNLOCKED';
  }
  if (typeof v0 === 'undefined' && Possible_Carrier) {
    v0 = `LOCKED ON ${Possible_Carrier}`;
  }
  if( v0 === 'LOCKED' && Possible_Carrier){
    v0 = `${v0} ON ${Possible_Carrier}`
  }

  if(v0 === 'UNLOCKED' && v4==="CLEAN"){
    v1 = 'Eligible for Activation';
    v2 = 'Eligible for Activation';
    v3 = 'Eligible for Activation';
  }
  if(v0?.includes("LOCKED") && Possible_Carrier?.includes("VERIZON") && v4 === "CLEAN"){
    v1 = 'Not Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Eligible for Activation';
  }
  if(v0?.includes("LOCKED") && Possible_Carrier?.includes("T-MOBILE")  && v4 === "CLEAN"){
    v1 = 'Not Eligible for Activation';
    v2 = 'Eligible for Activation';
    v3 = 'Not Eligible for Activation';
  }
  if(v0?.includes("LOCKED") && Possible_Carrier?.includes("AT&T")  && v4 === "CLEAN"){
    v1 = 'Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Not Eligible for Activation';
  }

  if(v0 === 'LOCKED ON VERIZON' && Possible_Carrier && Possible_Carrier.includes('VERIZON') && v4==="CLEAN"){
    v1 = 'Not Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Eligible for Activation';
  }
  if(v0 === 'MetroPCS Locked Policy' && Possible_Carrier && Possible_Carrier.includes('MetroPCS') && v4==="CLEAN"){
    v1 = 'Not Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Not Eligible for Activation';
  }
  if(v0 === 'LOCKED ON T-MOBILE' && Possible_Carrier && Possible_Carrier.includes('T-MOBILE') && v4==="CLEAN"){
    v1 = 'Not Eligible for Activation';
    v2 = 'Eligible for Activation';
    v3 = 'Not Eligible for Activation';
  }
  if(v0 === 'LOCKED ON AT&T' && Possible_Carrier && Possible_Carrier.includes('AT&T') && v4==="CLEAN"){
    v1 = 'Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Not Eligible for Activation';
  }
  if(typeof v0 === 'string' && v0.includes('LOCKED') && Possible_Carrier && Possible_Carrier.includes('AT&T') && v4==="CLEAN"){
    v1 = 'Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Not Eligible for Activation';
  }



  if(typeof v0 === 'string' && v0.includes('LOCKED') && (Possible_Carrier && Possible_Carrier.includes('AT&T')) && v1==='Server Down'){
    v1 = 'Server Down';
    v2 = 'Not Eligible for Activation';
    v3 = 'Not Eligible for Activation';
  }
  if(typeof v0 === 'string' && v0.includes('LOCKED')  && (Possible_Carrier && (Possible_Carrier.includes('T-MOBILE') || Possible_Carrier.includes('SPRINT')) ) && v2==='Server Down'){
    v1 = 'Not Eligible for Activation';
    v2 = 'Server Down';
    v3 = 'Not Eligible for Activation';
  }
  if(typeof v0 === 'string' && v0.includes('LOCKED')  && (Possible_Carrier && Possible_Carrier.includes('VERIZON')) && v3==='Server Down'){
    v1 = 'Not Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Server Down';
  }
  if(!Possible_Carrier && v0 === 'LOCKED'){
    v1 = 'Not Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Not Eligible for Activation';
  }

  if (Possible_Carrier && !Possible_Carrier.includes('VERIZON') && !Possible_Carrier.includes('T-MOBILE') && !Possible_Carrier.includes('AT&T') && v4 === "CLEAN") {
    v1 = 'Not Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Not Eligible for Activation';
  }
  
  if(Possible_Carrier && Possible_Carrier.includes('UNLOCKED')){
    v1 = 'Eligible for Activation';
    v2 = 'Eligible for Activation';
    v3 = 'Eligible for Activation';
  }

  if(Possible_Carrier && v0 === 'UNLOCKED'){ 
     v1 = 'Eligible for Activation'; 
     v2 = 'Eligible for Activation'; 
     v3 = 'Eligible for Activation'; 
   }

   if(Possible_Carrier?.includes("OPEN (VERIZON)")){
    v1 = 'Not Eligible for Activation';
    v2 = 'Not Eligible for Activation';
    v3 = 'Eligible for Activation'
    v0 = Possible_Carrier
  }
  if(Possible_Carrier?.includes("OPEN (T-MOBILE)")){
    v1 = 'Not Eligible for Activation';
    v2 = 'Eligible for Activation'
    v3 = 'Not Eligible for Activation';
    v0 = Possible_Carrier
  }
  if(Possible_Carrier?.includes("OPEN (AT&T)")){
    v1 = 'Eligible for Activation'
    v2 = 'Not Eligible for Activation';
    v3 = 'Not Eligible for Activation';
    v0 = Possible_Carrier
  }
  if(Possible_Carrier?.includes("OPEN")){
    v1 = 'Eligible for Activation'
    v2 = 'Eligible for Activation'
    v3 = 'Eligible for Activation'
    v0 = Possible_Carrier
  }
  
    switch(v1){
      case 'CLEAN': 
      v1='CLEAN / Eligible for Activation';
      v2 = 'Not eligible for this network'
      v3 = 'Not eligible for this network'
      break;
      default : break;
    }

    switch(v2){
      case 'CLEAN': 
      v2='CLEAN / Eligible for Activation';
      v1 = 'Not eligible for this network'
      v3 = 'Not eligible for this network'
      break;
      default : break;
    }

    switch(v3){
      case 'CLEAN': 
      v3='CLEAN / Eligible for Activation';
      v1 = 'Not eligible for this network'
      v2 = 'Not eligible for this network'
      break;
      default : break;
    }
  return (
    <div ref={REF} className=" rounded-lg my-10 p-5 px-1 sm:px-5  shadow-xl ">
      <div className="flex  items-center">
        <img src={logo_3} alt={"logo_3"} className="mr-3" />
        <h1 className="text-black font-bold text-2xl  ">Carrier Details</h1>
      </div>
      <div className="mt-3">
        <ITEM_1
          t={"SIM / Carrier Status"}
          v={v0}
          v1 = {Possible_Carrier}
          x={img1}
        />
        <ITEM_2 t={"AT&T Activation "} v={v1} x={img2} />
        <ITEM_2 t={"T Mobile Activation "} v={v2} x={img3} />
        <ITEM_2 t={"Verizon Activation "} v={v3} x={img4} />
      </div>
    </div>
  );
}

function ITEM_1({ t, v,v1, x }) {

  if(v==='LOCKED'){v=v+" - "+v1}
  return (
    <div className="bg-gray-100 flex text-black border-b ">
      <div className="w-1/2 p-2 ">
        <div className="text-base">{t}</div>
      </div>
      <div className="w-24 flex items-center ">
        <img src={x} alt={"lock"} style={{ width: x === img1 ? 18 : 70 }} />
      </div>
      <div className="w-1/2 p-2 ">
      <div
      className={`${v === "CLEAN" || v==='CLEAN / Eligible for Activation' || v==="Eligible for Activation" || v==="UNLOCKED" ? "text-green-500 " : "text-red-500 "}
     
      text-base`}
    >
          {v}
        </div>
      </div>
    </div>
  );
}
function ITEM_2({ t, v, x }) {
  return (
    <div className="flex text-black border-b">
      <div className="w-1/2 p-2  ">
        <div className="text-base">{t}</div>
      </div>
      <div className="w-24 flex items-center ">
        <img src={x} alt={"lock"} style={{ width: 60 }} />
      </div>
      <div className="w-1/2 p-2   ">
      <div  className={`${v === "CLEAN" || v==='CLEAN / Eligible for Activation' || v==="Eligible for Activation" || v==="UNLOCKED"  ? "text-green-500 " : "text-red-500 "}
      text-base`}
 >
          {v}
        </div>
      </div>
    </div>
  );
}
export default CARRIER_DETAILS;
