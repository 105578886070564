import { useEffect, useState } from "react";
import { toast } from "react-toastify";


function FAQS() {
  const [CN, setCN] = useState(1);
  const [questions, setQuestions] = useState([])
  const apiUrl = 'https://api.cellercertified.com/questions';
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setQuestions(data.questions);
        } else {
          toast.error("Unable to fetch questions");
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
        toast.error("An error occurred while fetching questions");
      }
    };

    fetchData();
  }, []);


  return (
    <div className=" sm:w-[60%] w-[90%] flex flex-col items-center  justify-center ">
         {questions.length > 0 ?questions.slice(0, 3).map((question, index) => {
       return <Q key={index+1} N={index+1} CN={CN} question={question} f={() => setCN(index+1)} />
      }):'no faqs'}
      {/* <Q N={1} CN={CN} f={() => setCN(1)} /> */}
      {/* <Q N={2} CN={CN} f={() => setCN(2)} />
      <Q N={3} CN={CN} f={() => setCN(3)} />
      <Q N={4} CN={CN} f={() => setCN(4)} /> */}
    </div>
  );
}

function Q({ N, CN, f,question }) {
  return (
    <div className=" w-[100%]">
      <div
        className={`px-5 m-1 p-3 shadow-sm cursor-pointer hover:text-[#2aa651] text-lg md:leading-10 ${
          N === CN ? " border-b border-gray-400 text-sky-600 shadow-sm " : "bg-gray-100"
        }   rounded-sm  font-medium flex  justify-between `}
        onClick={() => {
          f();
        }}
      >
        <h1>{question?.question}</h1>
        <button
          className="text-[18px] font-bold"

        >
          {N === CN ? "-" : "+"}
        </button>
      </div>

      {N === CN && (
        <div className="px-5 m-1 p-1 w-[100%] text-lg md:leading-10	">
          <h1>{question?.answer}</h1>
         
        </div>
      )}
      
    </div>
  );
}

export default FAQS;
