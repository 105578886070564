import { useEffect, useState ,useRef} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
// import 'tailwindcss/tailwind.css';
import { useLocation } from 'react-router-dom';
import { MoonLoader } from "react-spinners";
import './styling.css'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import SubscriptionForm  from '../components/stripe_Subscription_form'
const stripePromise = loadStripe("pk_test_51NryWTKfQ1sQ4OinftW73z10043TnzxQR3YAtMmjwLXYg9EKgzpVeszdaxkbnGBonCzqhpblQKFYouuYcDc2C4iH00dAFxTptF");

//test
function GetStarted() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pkg_code = queryParams.get('pkg_code');
    const [is_new_user,setusertype] =useState();
    const [div_no,set_div_no] = useState(1);
    const HEADINGS = [" Welcome ", "Get Started","Personal Information","Address" , "Verify OTP","Choose a Package" ,"Payment Information" ,"Thanks ! " ]

    const fun_set_user_type = (X)=>{setusertype(X);}


    const API_Register_USER = ()=>{



    }
    
    const [Email,set_Email] = useState("default@gmial.com");
    const [Fname,set_Fname] = useState("");
    const [LastName,set_LastName]  = useState("");
    const [Password,setpasswords] = useState("");
    const [conform_password,set_ConfirmPassword] = useState("");
    const [Phone,setphone] = useState("");

    const [Address,setaddress] =useState("")
    const [zipcode,setzipcode] =useState("")
    const [country,setcountry] =useState("")
    const [city,setcity] =useState("")

    const fun_set_adress = (x)=>{ setaddress(x)}
    const fun_set_zipcode = (x)=>{ setzipcode(x)}
    const fun_set_country = (x)=>{ setcountry(x)}
    const fun_set_city = (x)=>{ setcity(x)}

    const fun_set_email = (x)=>{ console.log(x); set_Email(x)}
    const fun_set_fname = (x)=>{ set_Fname(x)}
    const fun_set_lname = (x)=>{ set_LastName(x)}
    const fun_set_pwd = (x)=>{ setpasswords(x)}
    const fun_set_cpwd = (x)=>{ set_ConfirmPassword(x)}
    const fun_set_phone = (x)=>{ setphone(x)}

    const [otp1,setotp1] = useState("");
    const [otp2,setotp2] = useState("");
    const [otp3,setotp3] = useState("");
    const [otp4,setotp4] = useState("");
    const [timer,settimer] = useState(60);
    const [otp_expired,setotp_expired] = useState(false);

    const fun_set_otp1= (x)=>{ setotp1(x)}
    const fun_set_otp2= (x)=>{ setotp2(x)}
    const fun_set_otp3= (x)=>{ setotp3(x)}
    const fun_set_otp4= (x)=>{ setotp4(x)}
    
    const [pkg_Selected,set_pkg]= useState(0)

    const fun_set_pkg = (x)=>{ set_pkg(x)}
    const move_to_div=(x)=>{ set_div_no(x)}

  

    function div0_checks(){

        if(is_new_user === undefined){
            toast.warn("Please select one option")
            return false;}
        return true;
    }
    function div1_checks(){
  
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(Email === "" || !emailRegex.test(Email)){
            toast.error("Please enter a valid email");
            return false;
        }
        return true;
       
    }    
    function div2_checks(){

        if(Fname === "" || LastName === "" || Password=== "" ||conform_password=== "" ||Phone=== "" )
        {
            toast.error("Please fill all the fields");
            return false;
        }

        if(Password !== conform_password){
            toast.error("Password do not match");
            return false;
        }
        
       
        return true;
    }
    function div3_checks(){

        if(Address === "" || zipcode === "" || country=== "" ||city=== ""  )
        {
            toast.error("Please fill all the fields");
            return false;
        }
       
        return true;
    }
    function div4_checks(){
        if(otp1 ===""||otp2 ===""||otp3 ===""||otp4 ===""){
            toast.error("Please fill all the fields");
            return false;
        }

        if(otp_expired){
            toast.error("OTP expired");
            return false;}
        return true;
    }
    function div5_checks(){

        if(pkg_Selected === 0){
            toast.error("Please select a package");
        return false;}
        return true;
    }

    function Resend_OTP(){
        settimer(60)
        setotp_expired(false);

    }

    useEffect(()=>{
        
        if(pkg_code === undefined){toast.error("Please select a package");}
   
            const intervalId = setInterval(() => {

                if (div_no !== 4 ){return;}
                if(timer <= 0 ){setotp_expired(true); return ;}
                
                settimer((prevSeconds) => prevSeconds - 1);

            
               
              }, 1000);
          
              return () => {
     
                clearInterval(intervalId);
              };
        
        

    },[div_no,timer])

    return ( 
        
        <div className="w-full h-[100vh]  justify-center items-center flex flex-col">

        <ToastContainer />
        <div className="w-full h-[30vh]  justify-center items-center flex">
    
        </div>
        
        <div className="bg-white w-full h-[90vh] justify-start flex flex-col">
        <div className=" w-full justify-center items-center flex ">
        <h1 className="text-4xl font-bold">{HEADINGS[div_no]}</h1>
        </div>
        
        <div className=" w-full h-full justify-center items-center flex flex-col">
      

        {div_no === 1 && <DIV_EMAIL 
            f0={move_to_div}
            f={fun_set_email}
            />}
        {div_no === 2 && <DIV_PERSONAL_INFO 
            EMAIL ={Email}
            f0={set_div_no} 
            f1={fun_set_fname} 
            f2={fun_set_lname}
            f3={fun_set_pwd}
            f4={fun_set_cpwd}
            f5={fun_set_phone}          />}
        {div_no === 3 && <DIV_BILLING_INFO
            f0={set_div_no}
            f={API_Register_USER}
            EMAIL= {Email}
            Fname={Fname}
            LastName= {LastName}
            Password={Password}
            Phone={Phone}
            />}
        {div_no === 4 && <DIV_OTP
            f1={fun_set_otp1}
            f2={fun_set_otp2}
            f3={fun_set_otp3}
            f4={fun_set_otp4}
            Email ={Email}
            time={timer}
            otp_expired={otp_expired}
            f = {Resend_OTP}
            f0={set_div_no}
            />}
        {div_no === 5 && <DIV_PACKAEG
            f={fun_set_pkg}
            />}
        {div_no === 6 && <DIV_PAYMENT pkg_code={pkg_code} EMAIL={Email}/>}
        {div_no === 7 && <DIV_FINISH/>}
        
      

        </div>     
        </div> 

        <div className="w-full h-[30vh]  justify-start items-center flex flex-col ">


        <div className="p-4 flex items-center">
        <DOT divno={div_no} no={1}/>
        <DOT divno={div_no} no={2}/>
        <DOT divno={div_no} no={3}/>
        <DOT divno={div_no} no={4}/>
        <DOT divno={div_no} no={6}/>
      
      

        </div>   
        </div>        
        </div>
        
        );
}

export default GetStarted;



function DOT (props){

    const {divno,no} = props;
    return(
    <div className={` 
        rounded-3xl m-1
        ${divno ===no ? 'bg-sky-400 w-4 h-4 ':' bg-gray-400  w-3 h-3'} 
        ${divno > no ? 'bg-green-400 w-3 h-3':''}  
        
        `
    }>
    </div>
    );
}

function DIV_USER_TYPE({f}){


    const [selected_package,set_selected_package] = useState(0)

    return(
        <div className="border shadow-lg rounded-lg p-6  justify-start  flex flex-col">     

       <h1 className="text-2xl font-semibold text-center pb-6"> Are you registered with cellercertified?</h1>
       <div className={`flex `}>
       <div className= {`
       w-44
       hover:cursor-pointer
       border shadow-lg rounded-lg px-6 py-4 mx-4 justify-start  
       flex flex-col ${selected_package === 1 ? 'bg-sky-400 text-white':'bg-white text-black' }`}
       onClick={()=>{set_selected_package(1);f(true)}}
       >
       <h1 className="w-full font-bold text-xl text-center pb-2"> yes</h1>
       </div>     
       
       
       <div 
       className={`
       w-44   hover:cursor-pointer
       border shadow-lg rounded-lg px-6 py-4 mx-4 justify-start  
       flex flex-col ${selected_package === 2 ? 'bg-sky-400 text-white':'bg-white text-black' }`}
       onClick={()=>{set_selected_package(2);f(false)}}
       >
      
       <h1 className="w-full font-bold text-xl text-center pb-2"> no</h1>
       </div>    

       </div>

        </div>
    )
}

function DIV_EMAIL({f0,f}){

    const [email, set_email] = useState('')
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [loading,setloading] = useState(false)

    function handleclick(){
        setloading(true);
        
        if(!emailRegex.test(email)){
            toast.error("Please enter a valid email");
            setloading(false);
            return ;
        }




                    var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
            "Email": email
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            fetch("https://api.cellercertified.com/User_Exists", requestOptions)
            .then(response => response.json())
            .then(result => {
            
                if(!result.status){ 
                        toast.success('new user')
                        f(email)
                        f0(2)
                }else{
                    toast.info('User Already Exists - please login to update subscription')
                 }
            setloading(false)
            })
            .catch(error => {
                toast.error('catch error')
                setloading(false)
            });



    }
    return(
        <div  className="d h-fit border shadow-lg rounded-lg p-8 justify-start  flex flex-col ">        
        <h1 className="text-2xl font-semibold p-2"> Email</h1>
        <input 
        disabled={loading} 
        type="email" placeholder="xyz@gmail.com" className="w-96 border text-xl p-2 rounded " 
        onChange={(e)=>{set_email(e.target.value) }}
        ></input>
        
        <button disabled={loading} className="b1"
        onClick={()=>handleclick()}
        >
        Verify Email
        </button>

        {loading&&
            <div className="d flex items-center">
        <MoonLoader size={18} className="mx-2"/>
        <h1>Verifying Email</h1>
        </div>}

        </div>
    )
}

function DIV_PERSONAL_INFO({f1,f2,f3,f4,f5,f0,EMAIL}){

    const [loading, setloading] = useState(false)
    const [Fname,set_Fname] = useState("");
    const [LastName,set_LastName]  = useState("");
    const [Password,setpasswords] = useState("");
    const [conform_password,set_ConfirmPassword] = useState("");
    const [Phone,setphone] = useState("");

    

    function handlenext(){
        setloading(true);
        if(
            Fname==='' ||
            LastName==='' ||
            Password==='' ||
            conform_password==='' ||
            Phone==='' 
            ){toast.error('Fill All the Fields') ; setloading(false); return}
            if(
                Password !== conform_password 
                ){toast.error('Password Donot match') ; setloading(false); return} 

        f1(Fname)
        f2(LastName)
        f3(Password)
        f4(conform_password)
        f5(Phone)
        f0(3)

    }

    return(
    <div className="border shadow-lg rounded-lg p-8 justify-start  flex flex-col">      
        
    <input disabled={true} 
        placeholder={`${EMAIL}`}
        value={`${EMAIL}`}
         className="w-96 border text-xl p-2 rounded m-2 font-bold " 
        type="Email"></input>

        <input 
        disabled={loading}  
        placeholder="First Name" className="w-96 border text-xl p-2 rounded m-2" 
        onChange={(e)=>{set_Fname(e.target.value)}}></input>

        <input 
        disabled={loading} 
        placeholder="Last Name" className="w-96 border text-xl p-2 rounded m-2" 
        onChange={(e)=>{set_LastName(e.target.value)}}></input>

        <input 
        disabled={loading} 
        placeholder="Password" className="w-96 border text-xl p-2 rounded m-2" 
        type="password"
        onChange={(e)=>{setpasswords(e.target.value)}}></input>

        <input 
        disabled={loading} 
        placeholder="Confirm Password" className="w-96 border text-xl p-2 rounded m-2" 
        type="password"
        onChange={(e)=>{set_ConfirmPassword(e.target.value)}}></input>

        <input 
        disabled={loading} 
        placeholder="Phone" className="w-96 border text-xl p-2 rounded m-2" 
        type="number"
        onChange={(e)=>{setphone(e.target.value)}}
        ></input>

        <button disabled={loading} 
        onClick={handlenext}
        className="b1">Next</button>
        </div>
    )
}

function DIV_BILLING_INFO({f0,EMAIL,Fname,LastName,Password,Phone}){
    const [Address,setaddress] =useState("")
    const [zipcode,setzipcode] =useState("")
    const [country,setcountry] =useState("")
    const [city,setcity] =useState("")
    const [loading,setloading] = useState(false)

    function handlenext(){

        setloading(true)
        if(Address === "" || zipcode === "" || country=== "" ||city=== ""  )
        {
            toast.error("Please fill all the fields");
            return false;
        }

        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        Email:EMAIL,
        First_Name:Fname,
        Last_Name:LastName,
        Password,
        Phone,
        Address,
        City:city,
        Country:country,
        Zipcode:zipcode
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("https://api.cellercertified.com/Register_with_otp", requestOptions)
        .then(response => {
            if (!response.ok) {
                console.log('Request failed with status:', response.status);
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            console.log('Response data:', result);
            if (result.status) {
                toast.success('User Data Sent');
                toast.info('OTP ' + result.data[0]);
                f0(4);
            } else {
                toast.error('Error sending user data');
                setloading(false);
            }
        })
        .catch(error => {
            console.error('Fetch error:', error);
        });
    
        

        
        
        
    }


    return(
        <div className="border shadow-lg rounded-lg p-8 justify-start  flex flex-col">      
            <input 
            disabled={loading} 
            placeholder="Address" className="w-96 border text-xl p-2 rounded m-2" 
            onChange={(e)=>{setaddress(e.target.value)}}
            ></input>
            <input 
            disabled={loading} 
            placeholder="Zip Code" className="w-96 border text-xl p-2 rounded m-2"
            onChange={(e)=>{setzipcode(e.target.value)}
        } 
            type="number"
            ></input>
            <input
            disabled={loading} 
             placeholder="Country" className="w-96 border text-xl p-2 rounded m-2"
            onChange={(e)=>{setcountry(e.target.value)}} ></input>
            <input
            disabled={loading} 
             placeholder="City" className="w-96 border text-xl p-2 rounded m-2" 
            onChange={(e)=>{setcity(e.target.value)}}></input>

            <button className="b1" onClick={handlenext}>NEXT</button>
            </div>
        )
}

function DIV_OTP({time,otp_expired,f,Email,f0}){
   
        const inputRefs = useRef([]);
      
        const handleInputChange = (index, event) => {
          if (event.target.value.length === event.target.maxLength) {
            const nextInput = inputRefs.current[index + 1];
            if (nextInput) {
              nextInput.focus();
            }
          }
        };


    const [loading,setloading] = useState(false)
    const [otp1,setotp1] = useState("");
    const [otp2,setotp2] = useState("");
    const [otp3,setotp3] = useState("");
    const [otp4,setotp4] = useState("");

    function handlenext(){
        setloading(true);
        
        if(otp1 ===""||otp2 ===""||otp3 ===""||otp4 ===""){
            toast.error("Unvalid OTP");
            setloading(false);
            return false;
        }


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var OTP = otp1+otp2+otp3+otp4;
        var raw = JSON.stringify({
          "Email": Email,
          "OTP": OTP
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch("https://api.cellercertified.com/Verify_OTP", requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.status){
                toast.success(result.msg)
                f0(6)
            
            }
            else{
                toast.error(result.msg);
                setloading(false);
            }
          })
          .catch(error => {
            toast.error('unable to verify otp')
            console.log('error', error)});

        
    }

    function Resend_OTP(Email, f) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
      
        var raw = JSON.stringify({
          "Email": Email
        });
      
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
      
        fetch("https://api.cellercertified.com/Resend_OTP", requestOptions)
        .then(response => {
            if (!response.ok) {
                console.log('Request failed with status:', response.status);
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            console.log('Response data:', result);
            if (result.status) {
                toast.success('New OTP sent ' + result.data[0]);
                if (f) {
                    f();
                }
            } else {
                toast.error('Error sending OTP');
            }
        })
        .catch(error => {
            toast.warning('Error: ' + error.message);
            console.error('Fetch error:', error);
        });
    
      }
      

    return(
        <div className="d border shadow-lg rounded-lg p-8 justify-start  flex flex-col">      
        <h1 className="d text-2xl font-semibold p-2"> OTP</h1>
        <div>
            <input disabled={loading}
            ref={(input) => (inputRefs.current[0] = input)}
            onChange={(e)=>{
                handleInputChange(0, e)
                setotp1(e.target.value)}}
            className="w-14 d border text-2xl p-2  rounded m-2 font-bold text-center" maxLength="1" />
            <input   disabled={loading}
            ref={(input) => (inputRefs.current[1] = input)}
            onChange={(e)=>{
                handleInputChange(1, e)
                setotp2(e.target.value)}}
            className="w-14 d border text-2xl p-2 rounded m-2 font-bold text-center" maxLength="1"></input>
            <input   disabled={loading}
            ref={(input) => (inputRefs.current[2] = input)}
            onChange={(e)=>{
                handleInputChange(2, e)
                setotp3(e.target.value)}}
            className="w-14 d border text-2xl p-2 rounded m-2 font-bold text-center" maxLength="1"></input>
            <input   disabled={loading}
            ref={(input) => (inputRefs.current[3] = input)}
            onChange={(e)=>{
                handleInputChange(3, e)
                setotp4(e.target.value)}}
            className="w-14 d border text-2xl p-2 rounded m-2 font-bold text-center" maxLength="1"></input>
        </div>

        { !loading &&<h1 className="text-lg p-1"> {time ===0? "OTP expired":"OTP Expires in "+time+" seconds"}</h1>}
       {!loading &&( (otp_expired) && 
        <button 
            onClick={()=>{Resend_OTP(Email)}}
            className="border-2  p-2 rounded text-lg">Resend</button>  )  }

            <button 
            disabled={time===0 || loading?true:false} 
            onClick={handlenext}
            className="b1">Verify</button>
            {loading&&
                <div className="d flex items-center">
            <MoonLoader size={18} className="mx-2"/>
            <h1>Verifying OTP</h1>
            </div>}
        </div>

        
        )
}

function DIV_PACKAEG({f}){
const [selected_package,set_selected_package] = useState(0)

    return(
        <div className="border shadow-lg rounded-lg p-6  justify-start  flex flex-col">     

       <h1 className="text-2xl font-semibold text-center pb-6"> Monthly</h1>
       <div className={`flex `}>
       <div className= {`
       hover:cursor-pointer
       border shadow-lg rounded-lg px-6 py-4 mx-4 justify-start  
       flex flex-col ${selected_package === 1 ? 'bg-sky-400 text-white':'bg-white text-black' }`}
       onClick={()=>{set_selected_package(1);f(1)}}
       >
       <h1 className="w-full font-bold text-xl text-center pb-2"> STARTER</h1>
       <h1 className="text-lg text-center"> $15/month</h1>
       <h1 className="text-lg text-center"> +$0.75/check</h1>
       <h1 className="text-lg text-center"> 200 monthly checks</h1>
       </div>     
       
       
       <div 
       className={`
       hover:cursor-pointer
       border shadow-lg rounded-lg px-6 py-4 mx-4 justify-start  
       flex flex-col ${selected_package === 2 ? 'bg-sky-400 text-white':'bg-white text-black' }`}
       onClick={()=>{set_selected_package(2);f(2)}}
       >
      
       <h1 className="w-full font-bold text-xl text-center pb-2"> PRO</h1>
       <h1 className="text-lg text-center"> $15/month</h1>
       <h1 className="text-lg text-center"> +$0.75/check</h1>
       <h1 className="text-lg text-center"> 200 monthly checks</h1>
       </div>    

       <div 
       className={`
       hover:cursor-pointer
       border shadow-lg rounded-lg px-6 py-4 mx-4 justify-start  
       flex flex-col ${selected_package === 3 ? 'bg-sky-400 text-white':'bg-white text-black' }`}
       onClick={()=>{set_selected_package(3);f(3)}}
       
       >
       <h1 className="w-full font-bold text-xl text-center pb-2"> ENTERPRISE</h1>
       <h1 className="text-lg text-center"> $15/month</h1>
       <h1 className="text-lg text-center"> +$0.75/check</h1>
       <h1 className="text-lg text-center"> 200 monthly checks</h1>
       </div>    
       
       </div>
       <h1 className="text-2xl font-semibold text-center p-6"> Pay as you go!</h1>
       <div>
       <div className="flex items-center justify-center">
       
       <div   className={`
       hover:cursor-pointer
       border shadow-lg rounded-lg px-6 py-4 mx-4 justify-start  
       flex flex-col ${selected_package === 4 ? 'bg-sky-400 text-white':'bg-white text-black' }`}
       onClick={()=>{set_selected_package(4);f(4)}}
       >
       <h1 className="w-full font-bold text-xl text-center pb-2"> Single Check</h1>
       </div>     
       
       
       <div   className={`
       hover:cursor-pointer
       border shadow-lg rounded-lg px-6 py-4 mx-4 justify-start  
       flex flex-col ${selected_package === 5 ? 'bg-sky-400 text-white':'bg-white text-black' }`}
       onClick={()=>{set_selected_package(5);f(5)}}
       >
       <h1 className="w-full font-bold text-xl text-center pb-2"> 150 Checks</h1>
       </div>    

       <div   className={`
       hover:cursor-pointer
       border shadow-lg rounded-lg px-6 py-4 mx-4 justify-start  
       flex flex-col ${selected_package === 6 ? 'bg-sky-400 text-white':'bg-white text-black' }`}
       onClick={()=>{set_selected_package(6);f(6)}}
       >
       <h1 className="w-full font-bold text-xl text-center pb-2"> 500 Checks</h1>
       </div>    


       </div>
       </div>

        </div>
    )
}

function DIV_PAYMENT({EMAIL,pkg_code}){
  
    let pkg_type = "none"
    let pkg_cost = "0.00$"
    let pkg_volume = "0.0/check"
    if(pkg_code === "p1"){ pkg_type = "Pay as you go"; pkg_cost="10.00$"; pkg_volume="1.22$ / check" }
    if(pkg_code === "p2"){ pkg_type = "Pay as you go"; pkg_cost="12.00$"; pkg_volume="0.5$ / check"}
    if(pkg_code === "p3"){ pkg_type = "Pay as you go"; pkg_cost="13.00$"; pkg_volume="0.22$ / check"}
    if(pkg_code === "m1"){ pkg_type = "Monthly"; pkg_cost="15.00$"; pkg_volume="100 checks"}
    if(pkg_code === "m2"){ pkg_type = "Monthly"; pkg_cost="50.00$";  pkg_volume="150 checks"}
    if(pkg_code === "m3"){ pkg_type = "Monthly"; pkg_cost="100.00$"; pkg_volume="200+ checks"}
  
    return (
      <div className="flex items-start justify-start  flex-col 
      p-8 shadow-lgs rounded-lg shadow-lg ">
        <div className="px-8 pb-2 w-full m-2">
        <h1 className="font-semibold text-lg">Email</h1>
        <h1 className="text-gray-500  text-lg w-full text-center  ">{EMAIL}</h1>
        <h1 className="font-semibold text-lg">Package</h1>
        <h1 className="text-gray-500   text-lg w-full text-center ">{pkg_type}</h1>
        <h1 className="font-semibold text-lg">Volume</h1>
        <h1 className="text-gray-500   text-lg w-full text-center ">{pkg_volume}</h1>
        <h1 className="font-semibold text-lg">Cost</h1>
        <h1 className="text-gray-500  text-lg w-full text-center ">{pkg_cost}</h1>
        </div>
      <Elements stripe={stripePromise}>
    <SubscriptionForm email={EMAIL} pkg_code={pkg_code}/>
    </Elements>

  
      </div>
    );
}

function DIV_FINISH(){
    
    return(
        <div className="border shadow-lg rounded-lg p-8 justify-start  flex flex-col 
        border-green-300 bg-green-500">        
        <h1 className="text-2xl font-semibold p-2 text-white"> Your Payment Was Successfull ! </h1>
        </div>
    )
}


