import logo_3 from "../images/Logo_3.png";
function BUYBACKVALUE({ v, s1, s2, s3, s4, filteredProducts, products, v4 }) {
  console.log(filteredProducts)

  let link;

  // Determine the link based on the value of v
  switch (v) {
    case 1:
      link = filteredProducts.find(product => product.condition === "Excellent")?.sellNowLink;
      break;
    case 2:
      link = filteredProducts.find(product => product.condition === "Poor")?.sellNowLink;
      break;
    case 3:
      link = filteredProducts.find(product => product.condition === "New")?.sellNowLink;
      break;
    case 4:
      link = filteredProducts.find(product => product.condition === "Fair")?.sellNowLink;
      break;
    default:
        break;
  }

console.log(link)
  return (
    <div className="relative rounded-lg my-10 p-5 px-5  sm:px-10 shadow-xl break-before-page">

    {/* <div className="absolute inset-0 flex justify-center items-center  border-2 bg-gray-400/50 z-10">
    <h1 className="absolute text-gray-600 font-extrabold text-3xl -rotate-45 z-20 ">COMING SOON!</h1>
    </div> */}

      <div className="flex  items-center">
        <img src={logo_3} alt={"logo_3"} className="mr-3" />
        <h1 className="text-black font-bold text-2xl  ">BuyBack Value  {v4 === 'BLACKLISTED' ? <span className="text-red-500">- BLACKLISTED</span> : ''}</h1>
      </div>

      <div className="relative z-0 flex items-center justify-center mt-5">
        <div className="w-11/12 h-3 bg-slate-200 rounded-2xl z-0 "></div>
        <div className=" w-11/12 h-2 absolute flex justify-between -top-1">
          <div
            className={`w-5 h-5 rounded-2xl shadow hover:cursor-pointer ${
              v === 1 ? "bg-sky-400 " : "bg-slate-300"
            }  z-10`}
            onClick={() => s1()}
          ></div>
          <div
            className={`w-5 h-5 rounded-2xl  z-10 shadow  hover:cursor-pointer ${
              v === 2 ? "bg-sky-400 " : "bg-slate-300"
            }`}
            onClick={() => s2()}
          ></div>
          <div
            className={`w-5 h-5 rounded-2xl  z-10 shadow hover:cursor-pointer ${
              v === 3 ? "bg-sky-400 " : "bg-slate-300"
            }`}
            onClick={() => s3()}
          ></div>
            <div
            className={`w-5 h-5 rounded-2xl  z-10 shadow hover:cursor-pointer ${
              v === 4 ? "bg-sky-400 " : "bg-slate-300"
            }`}
            onClick={() => s4()}
          ></div>
        </div>
      </div>

      <div className="flex justify-center">
      <div
        className={`w-[100%] p-2 flex justify-between -top-1`}
      >
{filteredProducts.map((product, index) => (
  <div key={index} className={`${v === index + 1 ? "text-sky-400" : ""} flex flex-col justify-center items-center`}>
    {product.condition && <div className="">{product.condition}</div>}
    <div>{product.condition === "Excellent"
      ? product.BBExcellent
      : product.condition === "Fair"
      ? product.BBFair
      : product.condition === "Poor"
      ? product.BBPoor
      : product.condition === "New"
      ? product.BBNew
      : "Not Available"}</div>
  </div>
))}

      </div>
      </div>
      {
        link &&       <div className="flex items-center justify-center">
        <a href={link} target="_blank" class="flex items-center justify-center w-11/12 my-3 h-16 bg-green-500 hover:bg-green-800 text-white font-bold rounded shadow-xl ">
          <div  className="text-xl">SELL YOURS NOW</div>
        </a>
      </div>
      }
      {
        v4 === 'BLACKLISTED' ? <div className="text-red-500 text-center">
          *This device is BLACKLISTED so it has no value as is. If the device is cleared from the Blacklist, the buyback value would be above. 
        </div>:       <div className="text-black text-center" style={{ fontSize: 10 }}>
        * Without running a functional diagnostic on your device, we cannot
        determine the actual condition so we are displaying a range you can
        expect to receive from a buyback company
      </div>
      }
    </div>
  );
}

export default BUYBACKVALUE;
