import { Link } from "react-router-dom";
import "./footer.scss";
import logo_black from "../images/lock_black.png";
function FOOTER() {
  return (
    <div className="w-full bg-black pb-10 flex flex-col text-center items-center justify-center print:hidden">
      <img src={logo_black} alt="Logo_Black" className="m-10 " />
      <div>
        <a href="/pricing" className="text-white font-bold mx-2 text-base sm:mx-6">
          PRICING
        </a>
        <a href="/faq" className="text-white font-bold mx-2 sm:mx-6 text-base">
          FAQS
        </a>
        <a href="/login" className="text-white font-bold mx-2 text-base sm:mx-6">
          LOGIN
        </a>
        <a href="/" className="text-sky-500 font-bold mx-2 text-base sm:mx-6">
          RUN REPORT
        </a>
      </div>
      <div className="links">
        <Link className="link" to="/data-collection">
          Data Collection
        </Link>
        <Link className="link" to="/imei-checks">
          IMEI Checks
        </Link>
        <Link className="link" to="/lock-detection">
          Lock Detection
        </Link>
        <Link className="link" to="/mobile-device-certification">
          Mobile Device Certification
        </Link>
      </div>
      <p className="m-5 font-bold text-gray-600">
        Copyright 2023 Celler Certified LLC
      </p>
    </div>
  );
}

export default FOOTER;
