
import { useLocation, useNavigate, } from 'react-router-dom';
import { useEffect } from "react";
import LOADER from "../components/Loader"



function MIDDLEWARE() {

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const ID = searchParams.get('payment_intent');

    useEffect(()=>{

        if(!ID){navigate("/"); return;}
        fetch(`https://api.cellercertified.com/report_state/${ID}`)
         .then(response => {
             response.json() 
             .then(data => {
                console.log(data)
                if(data.status){
                    navigate(`/thankyou/?email=${data.Email}&uid=${data.UID}&imei=${data.IMEI}`)
                    return;
                }
                navigate(`/publicreport/?UID=${ID}`)
                //  window.open(data, '_self');
                });})
         .catch(error => console.error(error));

        console.log(ID)

    },[])


    return ( <div>
        
        <LOADER/>
        </div> );
}

export default MIDDLEWARE;