import { useState } from "react";
import PricingTabs from "./PricingTabs";
import "./ChoosePlan.scss";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChoosePlan = () => {
  const [currentstate, setcurrentstate] = useState(false);
  function handleRadioChange(e) {
    setcurrentstate(() => e.target.value);
  }
  function handlePayClick() {
    if (!currentstate) {
      toast.error("pick a plan first");
      return;
    }
    const url = `/getstarted/?pkg_code=${currentstate}`;
    window.open(url, "_self");
  }

  
  return (
    <div className="pricing">
      <ToastContainer />
      <div className="top pt-20">
        <div className="optionbelowtext">
          <PricingTabs />
        </div>
      </div>
      <div className="ChoosePlanPriceSection">
        <div className="ChooseplanBox">
          <div
            className="outlineBox"
            style={{
              backgroundColor: currentstate === "p1" && "#00aeef",
              color: currentstate === "p1" && "#fff",
            }}
          >
            <label className="containerr p-4">
              Single Check
              <input
                type="radio"
                value="p1"
                name="radio"
                onChange={handleRadioChange}
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div className="ChooseplanBox">
          <div
            className="outlineBox"
            style={{
              backgroundColor: currentstate === "p2" && "#00aeef",
              color: currentstate === "p2" && "#fff",
            }}
          >
            <label className="containerr p-4">
              150 Checks
              <input
                type="radio"
                value="p2"
                name="radio"
                onChange={handleRadioChange}
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div className="ChooseplanBox">
          <div
            className="outlineBox"
            style={{
              backgroundColor: currentstate === "p3" && "#00aeef",
              color: currentstate === "p3" && "#fff",
            }}
          >
            <label className="containerr p-4">
              500 Checks
              <input
                type="radio"
                value="p3"
                name="radio"
                className="Pricing500Check"
                onChange={handleRadioChange}
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="paybtn">
        <button
          className="PayNowBtn"
          style={{ backgroundColor: "#00aeef" }}
          onClick={() => {
            handlePayClick();
          }}
        >
          {" "}
          Pay Now{" "}
        </button>
      </div>
    </div>
  );
};

export default ChoosePlan;
