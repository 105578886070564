
import Thank_You_Logo from'../images/thankyou_logo.svg'
function SUBTHANKYOU() {
   

    return ( 
        <div className=" flex flex-col items-center justify-center h-[100vh] ">
        <div className="justify-center text-center w-[350px] mb-10">
          <h1 className="text-sky-500 font-bold text-3xl">Thank You!</h1>
          <p className="text-gray-700 text-lg">Your payment was successful.</p>
          <p className="text-gray-700 text-lg">Thank you for your Registration.</p>
        </div>

        <div>
        
        <div className='border rounded-lg shadow-lg px-6 py-8  sm:w-[430px] w-[350px] pt-12'>
        
        <div className='absolute  '>
        
        <img className=' relative -top-20 sm:left-40 left-32 w-[60px]' alt='thank you logo' src={Thank_You_Logo} ></img>
        </div>
      
        <div className='flex border-b py-5'><a className='btn bg-[#00A651] p-4 w-full text-center font-bold' href={`/login`} >Login Now</a></div>
        </div>

      </div>
      </div>
        
        );
}

export default SUBTHANKYOU;
