import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import HOME from "./Views/Home";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Views/login/Login";
import TOOLBAR from "./components/toolbar";
import Register from "./Views/Register/Register";
import Pricing from "./Views/pricing/Pricing";
import FOOTER from "./components/footer";
import ChoosePlan from "./Views/pricing/ChoosePlan";
import PricingPlans from "./Views/pricing/PricingPlans";
import PUBLIC_REPORT from "./Views/Public_Report/Public_Report";
import Datacollection from "./components/Datacollection";
import IMEIChecks from "./components/IMEIChecks";
import LockDetection from "./components/LockDetection";
import MobileDeviceCertification from "./components/MobileDeviceCertification";
import Unregistered from "./Views/Unregistered";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import useReducer from "./features/user.js";
import MIDDLEWARE from "./Views/Middleware";
import THANKYOU from "./Views/thankyou"
import STRIPE_PAY from "./Views/stripe_payment";
import FAQs from "./components/FaqChecks";
import SampleReport from "./Views/Sample_report";
import ForgetPassword from "./Views/ForgetPassword";
import GetStarted from "./Views/GetStarted";
import BackToTopArrow from './components/Back_To_Top';
import SUBTHANKYOU from './Views/Subscription_Thankyou'
import '@fortawesome/fontawesome-free/css/all.css';
import BATCH_REPORT from "./Views/Batch_Report/Batch_Report.jsx";

const store = configureStore({
  reducer: {
    user: useReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="leading-5">
  <BrowserRouter>
    <Provider store={store}>
      <TOOLBAR />

      <Routes>
        <Route path="/report" element={<App />} />
        <Route path="/publicreport" element={<PUBLIC_REPORT />} />
        <Route path="/batchReport" element={<BATCH_REPORT/>}/>
        <Route path="/unreg" element={<Unregistered />} />
        <Route path="/" element={<HOME />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/ForgetPassword" element={<ForgetPassword/>} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/chooseplan" element={<ChoosePlan />} />
        <Route path="/pricing" element={<PricingPlans />} />
        <Route path="/payasyougo" element={<ChoosePlan></ChoosePlan>} />
        <Route path="/data-collection" element={<Datacollection />} />
        <Route path="/imei-checks" element={<IMEIChecks />} />
        <Route path="/lock-detection" element={<LockDetection />} />
        <Route path="/faq" element={<FAQs />} />
        <Route path="/sample_report" element={<SampleReport />} />
        <Route path="/mobile-device-certification" element={<MobileDeviceCertification />}/>
        <Route path="/Middleware" element={<MIDDLEWARE />} />
        <Route path="/sp" element={<STRIPE_PAY />} />
        <Route path="/getstarted" element={<GetStarted/>} />
        <Route path="/thankyou" element={ <THANKYOU/>} />
        <Route path="/SUBTHANKYOU" element={ <SUBTHANKYOU/>} />
       
      </Routes>
      <BackToTopArrow />
      <FOOTER />
    </Provider>
  </BrowserRouter>
  </div>
);
reportWebVitals();
