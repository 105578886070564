import { useEffect } from "react";
import InnerHeader from "../components/InnerHeader";
import { BUYSELL, BUYBACK, ELEM, CLOUD } from "../SVGs";
import FAQS from "../components/FAQ";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import getYour from "../images/svgs/get-your.svg";
import viewSample from "../images/svgs/view-sample.svg";
import phoneImgSec3 from "../images/carrier-img.svg";
import phoneImgSec4 from "../images/lost-stolen.svg";
import phoneImgSec5 from "../images/devicelock.svg";
import "aos/dist/aos.css";
const l1 = require("../images/usell-logo.png");

const l2 = require("../images/buybackworld-logo 1.png");

function HOME() {
  const navigate = useNavigate();

  const myRef = useRef(null);
  function Scroll_TO_InnerHeader() {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }
  function focusEnterIMEI() {
    var inputElement = document.getElementById("IMEI_INPUT");
    inputElement.focus();
  }

  return (
    <>
      <div className="wrap">
        <InnerHeader
          REF={myRef}
          t1={"Buy"}
          t1_1={"or"}
          t1_2={"Sell"}
          t1_3={"Used Devices With Confidence"}
          t2={
            "Get the Facts You Need to Avoid Costly Hidden Problems with a CellerCertified Device History Report"
          }
        />

        <div className="body-area">
          <div className="container xl:max-w-screen-xl mx-auto px-10 sm:px-0 devices-status">
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="text-center mb-5 md:mb-20"
            >
              <h2 className="font-bold text-sky-500 md:text-[2.4rem] md:leading-[2.6rem] text-lg pt-10 pb-1">
                A Device’s Status & Eligibility Affect Its Value!
              </h2>
              <span className="pb-10 lg:text-2xl text-lg ">
                Every CellerCertified Device History Report Checks For
              </span>
            </div>
            <div className="flex flex-col lg:flex-row device-status-area md:pb-20 pb-5 justify-center items-center">
              <div className="sm:basis-2/4 basis-full md:px-10 px-0">
                <img src={phoneImgSec3} alt="" />
              </div>
              <div className="sm:basis-2/4 basis-full md:px-10 px-0">
                <span className="text-sky-500 lg:text-3xl text-xl font-bold pb-3 block">
                  Carrier Eligibility
                </span>
                <p className="lg:text-2xl text-base">
                  Good to go. Make sure your device is clear for activation with
                  all major US carriers and free of unpaid bills or other
                  financial issues.{" "}
                  <a
                    href="#topgoes"
                    className="font-bold text-green-600 hover:text-sky-500"
                    onClick={() => {
                      Scroll_TO_InnerHeader();
                    }}
                  >
                    Check Now
                  </a>
                </p>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="flex flex-col flex-col-reverse lg:flex-row device-status-area sm:pb-20 pb-5 justify-center items-center"
            >
              <div className="sm:basis-2/4 basis-full md:px-10 px-0">
                <span className="text-sky-500 lg:text-3xl text-xl font-bold pb-3 block">
                  Lost or Stolen
                </span>
                <p className="lg:text-2xl text-base">
                  No issues here. Devices reported lost or stolen are globally
                  blacklisted and cannot be activated anywhere in the world.{" "}
                  <a
                    href="#topgoes"
                    className="font-bold text-green-600 hover:text-sky-500"
                    onClick={() => {
                      Scroll_TO_InnerHeader();
                    }}
                  >
                    Eliminate that issue now
                  </a>
                </p>
              </div>
              <div className="sm:basis-2/4 basis-full md:px-10 px-0">
                <img src={phoneImgSec4} alt="" />{" "}
              </div>
            </div>
            <div className="flex flex-col lg:flex-row device-status-area sm:pb-20 pb-5 justify-center items-center">
              <div className="sm:basis-2/4 basis-full md:px-10 px-0">
                <img src={phoneImgSec5} alt="" />{" "}
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                className="sm:basis-2/4 basis-full  md:px-10 px-0"
              >
                <span className="text-sky-500 lg:text-3xl text-xl font-bold pb-3 block">
                  Device Locks
                </span>
                <p className="lg:text-2xl text-base">
                  Looks can be deceiving. Even a perfectly good-looking device
                  can be registered to another user or company, rendering it
                  useless.{" "}
                  <a
                    href="#topgoes"
                    className="font-bold text-green-600 hover:text-sky-500"
                    onClick={() => {
                      Scroll_TO_InnerHeader();
                    }}
                  >
                    Check Now
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className="bg-[rgba(248,248,248,1)] flex flex-col justify-center  w-full items-center p-5 mt-[80px]"
        >
          <h1 className="text-4xl font-bold m-10 ">Why Use CellerCertified?</h1>
          <div className="flex flex-row flex-wrap sm:w-[80%] items-center justify-center ">

            <ITEM2
           
              i={1}
              t1={"Buy and Sell For Top Dollar"}
              t2={
                "Eliminate all issues and ensure your device is clear for activation with proof so you get the highest resale value possible."
              }
              t3={"Get Your Device History Report Now"}
            />
            <ITEM2
              i={2}
              t1={"BuyBack Guarantee"}
              t2={
                "No risk at all. If your device has any activation issues not found in our report, we'll buyback your device. It's that simple."
              }
              t3={"Get Your Device History Report Now"}
            />
            <ITEM2
              i={3}
              t1={"Cloud Based System"}
              t2={
                "Instantly check one or multiple IMEIs from anywhere in the world. No desktop software required."
              }
              t3={"Get Your Device History Report Now"}
            />
            <ITEM2
              i={4}
              t1={"Eliminate Costly Mistakes"}
              t2={
                "Other systems make your staff select from many different checks to run, which leads to mistakes and downtime. We run everything efficiently with one click. "
              }
              t3={"Get Your Device History Report Now"}
            />
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className="container lg:max-w-screen-lg mx-auto"
          >
            <div className="flex flex-col sm:flex-row itmes-center justify-center ">
              <BTN
                T="GET YOUR REPORT NOW"
                image={getYour}
                color={"bg-green-600"}
                f={focusEnterIMEI}
              />
              <BTN
                T="VIEW SAMPLE HISTORY REPORT"
                image={viewSample}
                color={"bg-sky-600"}
                f={() => {
                  navigate("/sample_report");
                }}
              />
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className="flex flex-col justify-center items-center border-b p-10 border-gray-400 text-center"
        >
          <h1 className="text-5xl font-bold tracking-wider">You’re In Good Company</h1>
          <h2 className="text-3xl tracking-widest">
            CellerCertified is trusted by the best in the used device industry
          </h2>
          <div className="lg:flex ">
            <img
              src={l2}
              alt="logo 2"
              className=" object-scale-down w-56 mt-5"
            />
            <img
              src={l1}
              alt="logo 1"
              className="object-scale-down w-20 mt-5 ml-5"
            />
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className="flex justify-center items-center flex-col mb-10"
        >
          <h1 className="text-2xl font-bold m-5 text-center">
            Frequently Asked Questions
          </h1>
          <FAQS />
          <div className="mt-3">
            <Link to="/faq" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Read More</Link>
          </div>
        </div>
      </div>
    </>
  );
}

function ITEM({ d, p, t1, t2, t3 }) {
  return (
    <div
      className={`flex contentImg flex-col ${
        d ? "sm:flex-row-reverse" : "sm:flex-row"
      }    justify-center items-center`}
    >
      <div>
        <img src={p} alt="img" className="w-80" />
      </div>
      <div className="mx-20 carrierbox">
        <h1 className="text-sky-600 text-lg font-bold  ">{t1}</h1>
        <h2 className="text-lg	">
          {t2}
          <span className="text-base font-bold text-green-600">{t3}</span>
        </h2>
        {/* <h3 className="text-lg font-bold text-green-600">{t3}</h3> */}
      </div>
    </div>
  );
}



function ITEM2({ i, t1, t2, t3 }) {
  // useEffect(() => {
  //   // 👇️ scroll to top
  //   window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  // }, []);

  return (
    <div className="flex flex-col justify-center items-center p-5">
      <div className="CellerCertifiedBoxs flex flex-col items-center shadow-lg bg-white rounded-md w-60 md:w-80  min-h-[22rem] text-center p-4">
        {i === 1 && <BUYSELL />}
        {i === 2 && <BUYBACK />}
        {i === 3 && <CLOUD />}
        {i === 4 && <ELEM />}
        <h1 className="font-bold p-2 text-lg">{t1}</h1>
        <h2 className="text-lg mx-3 ">{t2}</h2>
        <a className="text-base cursor-pointer font-bold hover:underline"   
          onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}
        >{t3}</a>
      </div>
    </div>
  );
}

function BTN({ f, T, image, color }) {
  return (
    <>
      <div className="sm:basis-2/4 basis-full justify-center items-center p-5">
        <div
          onClick={() => {
            f();
          }}
          className="items-center text-center p-5 hover:cursor-pointer"
        >
          <img src={image} alt="" />
        </div>
      </div>
    </>
  );
}
export default HOME;
