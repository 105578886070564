import React, { useEffect, useState } from 'react';
import TOOLBAR from './toolbar';
import InnerHeader from './InnerHeader';
import LockDetction from '../images/faqsvg/lock-detection copy-01.svg'
import MarketPlace from '../images/faqsvg/marketplace copy-01.svg'
import IndividualReports from "../images/faqsvg/reports-individual copy-01.svg"
import { toast } from "react-toastify";

const IMEIChecks = () => {
  
  // const [token,setToken] =useState('')
  const [questions, setQuestions] = useState([])
  const apiUrl = 'https://api.cellercertified.com/questions';
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setQuestions(data.questions);
        } else {
          toast.error("Unable to fetch questions");
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
        toast.error("An error occurred while fetching questions");
      }
    };

    fetchData();
  }, []);
   
  const FaqItem = ({ question, answer }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
      setExpanded(!expanded);
    };

  

    return (
      <div className="mb-2">
        <div className="flex justify-between items-center cursor-pointer bg-gray-100 p-2"
          onClick={toggleExpand}>
          <button
            className=" text-blue-500 text-left text-lg font-bold hover:text-[#2aa651]"
          >
            {question}
          </button>
          <button
            className={`w-6 h-6 flex items-center justify-center transition-transform transform ${
              expanded ? 'rotate-0' : 'rotate-180'
            }`}
          >
            <span className="w-2 h-2 border-t-2 border-r-2 border-blue-500 transform -rotate-45"></span>
          </button>
        </div>
        {expanded && (
          <p className="mt-2 ">{answer}</p>
        )}
      </div>
    );
  };

  function FaqImages(){

    return(
      <div className="flex py-0 sm:py-16">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto mt-10 max-w-2xl sm:mt-0 lg:mt-0 lg:max-w-4xl">
      <div className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
        <div className="flex flex-col justify-center items-center relative transition duration-1000 hover:scale-110">
            <div className='w-24 h-24'><img src={LockDetction} alt='Lock Detection'/></div>
           <h2 className='mt-1 text-lg font-bold'>Lock Detection</h2>
          <p className="mt-1 text-base leading-7  text-center">Checks Global Blacklist, manufacturer and carrier locks.</p>
        </div>
        <div className="flex flex-col justify-center items-center relative transition duration-1000 hover:scale-110">
            <div className='w-24 h-24'><img src={MarketPlace} alt='Lock Detection'/></div>
           <h2 className='mt-1 text-lg font-bold'>Marketplace sellers</h2>
          <p className="mt-1 text-base leading-7 text-center">Increase release value with certified devices.</p>
        </div>
        <div className="flex flex-col justify-center items-center relative transition duration-1000 hover:scale-110">
            <div className='w-24 h-24'><img src={IndividualReports} alt='Lock Detection'/></div>
           <h2 className='mt-1 text-lg font-bold'>Individual Reports</h2>
          <p className="mt-1 text-base leading-7 text-center"> Print barcode and PDF reports from your cloud portal. </p>
        </div>
      </div>
    </div>
  </div>
</div>
    )
  }

  return (
    <div>
      <TOOLBAR />
      <InnerHeader
          t1={"Buy"}
          t1_1={"or"}
          t1_2={"Sell"}
          t1_3={"Used Devices With Confidence"}
          t2={
            "Get the Facts You Need to Avoid Costly Hidden Problems with a CellerCertified Device History Report"
          }
        />
        <FaqImages/>
      <div className="inner-area py-10 container lg:max-w-screen-lg mx-auto px-5">
        <h1 className="text-[1.4rem] md:text-[2.4rem] font-bold text-center mb-5">CellerCertified FAQs</h1>
        <div>
        {questions.map((item, index) => (
            <FaqItem key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default IMEIChecks;
