import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./login.css";

const Login = () => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Status, setStatus] = useState(null);
  const [Remember, setRemember] = useState(false);
  const [token, setToken] = useState(null);

  function remember() {
    if (Remember) {
      localStorage.setItem("Email", Email);
      localStorage.setItem("Password", Password);
    } else {
      localStorage.removeItem("Email", Email);
      localStorage.removeItem("Password", Password);
    }
  }

  useEffect(() => {
    const storedEmail = localStorage.getItem("Email");
    const storedPassword = localStorage.getItem("Password")
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword)
      setRemember(true);
    }
  }, []);

  function handlePWDChange(event) {
    setPassword(event.target.value);
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function isValidEmail(email) {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  function LOGIN() {
    setLoading(true);

    if (Email === "" || Password === "" || !isValidEmail(Email)) {
      setLoading(false);
      setStatus(false);
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("Email", Email);
    urlencoded.append("Password", Password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    const api2 = "https://api.cellercertified.com/login";

    fetch(api2, requestOptions).then((response) => {
      response
        .text()
        .then((result) => {
          var jsonObject = JSON.parse(result);
          console.log(jsonObject.status);
          setStatus(jsonObject.status);
          setToken(jsonObject.token);
          // sessionStorage.setItem("token", jsonObject.token);
        })
        .catch((error) => console.log("error", error))
        .finally((e) => {
          setLoading(false);
          remember();
        });
    });
  }

  useEffect(() => {
    if (Status) {
      window.location.href = `https://www.dash.cellercertified.com/?token=${token}`;
    }
  }, [Status]);

  return (
    <div className="login wrap">
      <div className="loginBox">
        <div className="loginFormContainer">
          <h1>Sign In</h1>
          <p>Please enter username and password to proceed further</p>
          <input
            id="Email_Field"
            value={Email}
            type="text"
            className="inputfield"
            placeholder="Your Email address"
            onChange={handleEmailChange}
          />
          <input
            id="Password_Field"
            value={Password}
            type="password"
            className="inputfield"
            placeholder="Your Password"
            onChange={handlePWDChange}
          />
          <br />
          <div className="rememberforget">
            <div>
              <input
                type="checkbox"
                checked={Remember}
                onClick={() => {
                  setRemember(!Remember);
                }}
              />
              &nbsp; Remember Me
            </div>
            <Link to="/ForgetPassword" style={{ color: "#00AEEF" }}>
              Forget Password
            </Link>
          </div>
          <button
            className={`bg-sky-500 h-12 text-[1.2rem] font-bold rounded text-white w-[100%] ${
              Loading ? "cursor-not-allowed bg-gray-600" : ""
            }`}
            onClick={LOGIN}
          >
            Sign in Now
          </button>
          {!Loading && Status && (
            <span className="text-green-500">Login Success!</span>
          )}
          {!Loading && Status === false && (
            <span className="text-red-500">Login Failed!</span>
          )}
          <span>
            <br />
            <br />
            Don't <span className="mx-1"> have</span> an <span className="mx-1">account</span>?&nbsp;
            <Link to="/register" style={{ color: "#00AEEF" }}>
              Sign Up Now
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
