import { Link } from "react-router-dom";
import "./Pricing.scss";
import PricingTabs from "./PricingTabs";

const PricingPlans = () => {
  function handleClick(price) {
    console.log(price);
  }
  return (
    <div className="pricing">
      {/* <div className="topcontentt">
        <h1>CHOOSE THE PLAN THAT’S RIGHT FOR YOU</h1>
        <p>
          Easy
          <span> Straight-Forward </span>
          Pricing. No annual
          <span> contracts, ever.</span>
        </p>
      </div> */}
      <PricingTabs />
      <h1 style={{ color: "#00AEEF" }}>Monthly Subscription</h1>
      <p>
        <span className="topBelowSpan">
          First check is FREE with every plan.{" "}
        </span>
        No annual contracts.
      </p>
      {/* </div> */}
      <div className="priceBoxSection">
        <div className="PriceBox">
          <h1>Starter</h1>
          <span>
            For individuals or companies just getting started with processing
            <br />
            devices
          </span>
          <h2 className="price">
            <b className="dollor">$15</b>/ month
          </h2>
          <h3 className="check">+ $0.75/check</h3>
          <p className="save">
            Save 60% on your checks and rest assured you’re buying and selling
            with no issues.
          </p>
          <button className="outlineButton" onClick={() => handleClick(15)}>
          <Link to={'/getstarted/?pkg_code=m1'} className="py-4">  Get Started with Starter</Link>
          </button>
          <p className="recomended">
            Recommended for up to
            <br />
            200 monthly checks
          </p>
        </div>
        <div className="PriceBox">
          <div className="eapps-pricing-table-column-ribbon">Most Popular</div>
          <h1 className="mt-4">Pro</h1>
          <span>
            For companies or individuals looking to process devices on a small
            scale with confidence
          </span>
          <h2 className="price">
            <b className="dollor">$50</b>/ month
          </h2>
          <h3 className="check">+ $0.55/check</h3>
          <p className="save">
            Save as much as 70% on your checks and get access to a dashboard to
            view and monitor all of your checks
          </p>
          <button className="outlineButton" onClick={() => handleClick(50)}>
          <Link to={'/getstarted/?pkg_code=m2'} className="py-4">
           Get Started with Pro 
           </Link>
          </button>
          <p className="recomended">
            Recommended for up to
            <br />
            1,000 monthly checks
          </p>
        </div>
        <div className="PriceBox">
          <div className="eapps-pricing-table-column-ribbon-bestvalue">
            Best Value
          </div>

          <h1 className="mt-4">Enterprise</h1>
          <span>
            For companies or individuals looking to process devices on a larger
            scale with full automation and reporting
          </span>
          <h2 className="price">
            <b className="dollor">$100</b>
            /month
          </h2>
          <h3 className="check">+ $0.50/check</h3>
          <p className="save">
            All the great features of a Pro account with 5 user accounts and API
            Access included.
          </p>
          <button className="outlineButton" onClick={() => handleClick(100)}>
           <Link to={'/getstarted/?pkg_code=m3'} className="py-4"> Get Started with Enterprise</Link>
          </button>
          <p className="recomended">
            Recommended for 1,000+
            <br />
            monthly checks
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingPlans;
