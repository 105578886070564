import React, { useEffect, useState, useRef } from "react";
import "./Forget.scss";

const ForgetPassword = () => {
  const [Email, setEmail] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [Status, setStatus] = useState(null);
  const [Remember, setRemember] = useState(false);
  //
  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);
  const [showDiv3, setShowDiv3] = useState(false);

  const toggleDiv1 = () => {
    setShowDiv1(false);
    setShowDiv2(true);
    setShowDiv3(false);
  };

  const toggleDiv2 = () => {
    setShowDiv2(false);
    setShowDiv1(false);
    setShowDiv3(true);
  };

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const handleInputChange = (event, ref) => {
    // if (event.target.value.length === event.target.maxLength) {
    //   ref.current.focus();
    // }
  };

  return (
    <>
      {/* <div className="forget">
        {showDiv1 ? (
          <div className="forgetbox">
            <div className="form">
              <h1>Forget Password</h1>
              <p>Enter your email </p>
              <input classname="input1" type="text" placeholder="Enter Email" />
              <button
                className={`bg-sky-500 h-12 text-[1.2rem] font-bold rounded text-white  w-[100%]`}
                onClick={toggleDiv1}
              >
                submit
              </button>
              <p className="text-red-500">Invalid Email</p>
            </div>
          </div>
        ) : null}

        <div className="foget">
          {showDiv2 ? (
            <div className="forgetbox">
              <div className="form">
                <h1>Verification</h1>
                <p>Enter your verifecation code</p>
                <div className="inputs flex">
                  <div className="px-5">
                    {" "}
                    <input
                      ref={input1Ref}
                      type="text"
                      maxLength={1}
                      onChange={(e) => handleInputChange(e, input2Ref)}
                      className="input2"
                      placeholder="1"
                    />
                  </div>

                  <div className="px-5">
                    <input
                      ref={input2Ref}
                      type="text"
                      maxLength={1}
                      onChange={(e) => handleInputChange(e, input3Ref)}
                      className="input2"
                      placeholder="2"
                    />{" "}
                  </div>

                  <div className="px-5">
                    <input
                      ref={input3Ref}
                      type="text"
                      maxLength={1}
                      onChange={(e) => handleInputChange(e, input4Ref)}
                      className="input2"
                      placeholder="3"
                    />
                  </div>
                  <div>
                    <input
                      ref={input4Ref}
                      type="text"
                      maxLength={1}
                      className="input2"
                      placeholder="4"
                    />
                  </div>
                </div>

                <button
                  className={`bg-sky-500 h-12 text-[1.2rem] font-bold rounded text-white  w-[100%]`}
                  onClick={toggleDiv2}
                >
                  submit
                </button>
                <p className="text-blue-400">Resend 60</p>
              </div>{" "}
            </div>
          ) : null}

          <div className="forgetbox">
            {" "}
            {showDiv3 ? (
              <div className="form">
                <h1>Reset Password</h1>

                <input
                  classname="input1"
                  type="password"
                  placeholder="Password"
                />
                <input
                  classname="input1"
                  type="password"
                  placeholder="Cnfrim Password"
                />
                <button
                  className={`bg-sky-500 h-12 text-[1.2rem] font-bold rounded text-white  w-[100%]`}
                >
                  Reset Password
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div> */}

      <div className="forget">
        <div className="loginBox">
          <div className="loginFormContainer">
            <h1>Forget Password</h1>
            <br />
            <p>Enter your email to proceed</p>
            <input
              id="Email_Field"
              // defaultValue={Email}
              type="email"
              className="inputfield"
              placeholder="Email address"
              onChange={handleInputChange}
            />
            <input
              type="submit"
              className={`bg-sky-500 h-12 text-[1.2rem] font-bold rounded text-white  w-[56%] ${
                Loading ? "cursor-not-allowed bg-gray-600" : ""
              }`}
              // onClick={LOGIN}
              value="Submit"
            />
            {/* </input> */}

            {/* {!Loading && Status && (
              <span className="text-green-500">Login Sucess!</span>
            )} */}

            {/* {!Loading && Status === false && (
              <span className="text-red-500">Login Failed!</span>
            )} */}
            <span>
              <br />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgetPassword;
