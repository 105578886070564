function FIND_IMEI_POPUP() {
    return ( 
        <div id="IMEI-Popup" className="w-[100%] h-screen fixed bg-[rgba(0,0,0,0.8)]  z-30 top-0 " hidden={true}>
         
        <div className="flex justify-center items-center w-[100%] h-screen">
        <div className="bg-white rounded-md  flex-col relative px-2 pb-5 w-[90%] sm:w-[40%] sm:px-10">
        <button className="bg-gray-300 px-2 rounded-sm justify-center absolute right-0 top-0 "
        onClick={()=>{Hide_popup()}}
        > x</button>
        <h1 className="m-2 font-bold text-2xl text-center">Where is my device's IMEI Number located?</h1>
        <div className="flex flex-col  ">
        <img className="w-48 sm:w-60" src="https://d2vh5kejdcy27x.cloudfront.net/images/serial-iphone-menu.jpg" alt="Img"/>
        <span >1.Unlock your iOS device or Android device, and go to <strong>Settings &rarr; General &rarr; About</strong>.</span>
        <span>2.Scroll down to find your "IMEI Number”</span>
        <span>3.Touch and hold on the IMEI Number and <strong>Copy</strong> should appear.</span>
        <span>4.Tap <strong>Copy</strong> to save the IMEI Number</span>
        <span>
              5.Paste the <span className="font-extrabold">
              IMEI </span> Number into the <span className="italic font-bold">Enter Your IMEI Number
                </span> searchbar on our site to receive your  <span className="font-extrabold">
              Device History Report!
                </span> 
            </span>
        </div>
        </div>
        </div>
        
        </div> );
}

function Hide_popup() {

    const Popup = document.getElementById("IMEI-Popup")
    Popup.hidden = true;

}


export default FIND_IMEI_POPUP;