import "./InnerHeader.css";
import FIND_IMEI_POPUP from "./Find_IMEI_Popup";
import React, { useEffect } from "react";
import HeaderPhone from "../../src/images/HeaderPhone.png";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { API_ROOT_PATH } from "../data";

const InnerHeader = ({ REF, t1, t1_1, t1_2, t1_3, t2 }) => {
  let navigate = useNavigate();

  const [imei, setimei] = useState("");
  const [Data, setData] = useState();

  const [Loading, setloading] = useState(false);
  const [Valid, setValid] = useState(false);
  const [FirstCheck, setFirstCheck] = useState(true);

  const controller = new AbortController();
  const signal = controller.signal;

  async function Check_IMEI() {
    if (imei.length !== 15) {
      setFirstCheck(true);
      console.log("");
      return;
    }

    setloading(true);

    // const api = `${API_ROOT_PATH}/validimei/${imei}`;
    const api = `https://api.cellercertified.com/validimei/${imei}`;
    await fetch(api)
      .then((data) => {
        data.json().then((json) => {
          setValid(json["valid"]);
          console.log(Valid)
          setloading(false);
        });
      })
      .catch((error) => {
        if (error.name === "AbortError") {
        }
      })
      .finally(() => {
        setFirstCheck(false);
      });
  }

  // useEffect(() => {
  //   Check_IMEI();
  // }, [imei]);

  const [inputValue, setInputValue] = useState("");
  

  function handleInputChange(event) {
    const value = event.target.value;
    if (value.length <= 15) {
      setInputValue(value);
      setimei(value);
    }
  }

  return (
    <div ref={REF}>
      <FIND_IMEI_POPUP />
      <div className="topheader-area">
        <div className="container xl:max-w-screen-xl mx-auto">
          <div className="header-area ">
            <div className="flex flex-row justify-center items-center pb-10 md:pb-0">
              <div className="basis-full md:basis-4/5 md:px-4">
                <div className="px-4 sm:px-0 z-20 h-85 text-white justify-center flex flex-col ">
                  <h2 className="text-[24px] md:text-4xl lg:text-5xl md:leading-[50px]  my-2 font-bold text-center md:text-left">
                    <span style={{ color: "#2CF0FD" }}>{t1} </span>
                    {t1_1}

                    <span style={{ color: "#2CF0FD" }}>{" " + t1_2} </span>
                    {t1_3}
                  </h2>
                  <span className="text-xl font-semibold text-center md:text-left">
                    {t2}
                  </span>
                  <div className="mt-5 mb-2 w-full md:flex">
                    <input
                      id="IMEI_INPUT"
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      className="
                    p-4 
                    h-16
                    w-full
                    border-[3px] 
                    border-sky-500 
                    md:w-[70%] 
                    sm:rounded-tl-lg 
                    sm:rounded-bl-lg 
                    placeholder:text-[1.25rem] 
                    text-black 
                    outline-none "
                      placeholder="Enter Your IMEI Number To Get Required Report"
                      type="number"
                      value={inputValue}
                      onChange={handleInputChange}
                    ></input>

                    <button
                      className={` hover:bg-sky-600 px-6 h-16 text-[1.25rem] md:text-[1.25rem] font-bold mt-4 md:mt-0 sm:rounded-tr-lg sm:rounded-br-lg w-full md:w-[35%] ${
                        imei.length === 15 
                        // && Valid
                          ? "bg-sky-500"
                          : "pointer-events-none bg-sky-700"
                      }`}
                      onClick={() => {
                        Check_IMEI();
                        navigate("/unreg", { state: { IMEI: imei } });
                      }}
                    >
                      Get Your Report Now
                    </button>
                  </div>
                  {Loading && (
                    <h3 className="text-green-500 font-semibold">
                      Varifying IMEI ...
                    </h3>
                  )}
                  {imei.length === 0 && (
                    <h3 className="text-green-500 font-semibold">
                    </h3>
                  )}
                  {!Loading && 
                  // Valid &&
                   imei.length === 15 && (
                    <h3 className="text-green-500 font-semibold">
                      Valid IMEI!
                    </h3>
                  )}
                  {
                  // !Loading && !Valid && !FirstCheck &&
                  imei.length !== 15 && imei.length > 0 &&
                   (
                    <h3 className="text-red-500 font-semibold">
                      Invalid IMEI!
                    </h3>
                  )}

                  <h3 className="text-[1.05rem] font-normal text-center md:text-left">
                    PRO TIP: Dial *#06# to view your IMEI or find it in
                    your&nbsp;
                    <button
                      style={{ color: "#2CF0FD" }}
                      onClick={() => {
                        const Popup = document.getElementById("IMEI-Popup");
                        Popup.hidden = false;
                      }}
                      className="font-semibold"
                    >
                      Device Settings
                    </button>
                  </h3>
                </div>
              </div>
              <div className="basis-2/5 sm:block hidden">
                <div className="bannerImage lg:relative lg:top-12 lg:left-8">
                  <img
                    // src={p1}
                    src={HeaderPhone}
                    alt="Phone 1 img"
                    className="h-72 right-[20%]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerHeader;
