import "../css/toolbar.css";
import { LOGO } from "../SVGs";
import Menu from "../images/menu.png";
import DROPDOWN from "./DropDown";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DropdownUser from "./DropdownUser";



function TOOLBAR() {
  const [dropdownActive, setDropdownActive] = useState(false);
    const token = localStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {
    setDropdownActive(false); // Close the dropdown when the page changes
  }, [location]);

  const handleToggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  return (
    <>
      <div className="toolbar_main z-20 shadow-md items-center justify-center flex-col sm:flex-row fixed">
        <div className="w-full sm:w-1/2 h-full flex justify-between items-center px-5 py-1">
          <Link to="/">
            <LOGO />
          </Link>
          <img
            src={Menu}
            alt="Menu Text"
            className="w-5 h-5 block sm:hidden hover:cursor-pointer"
            onClick={handleToggleDropdown}
          />
        </div>
        <div className="hidden sm:flex">
        {token ? (<>

          </>):(<>          <TOOLBAR_ITEM txt={"PRICING"} url="/pricing" /></>)}
          <TOOLBAR_ITEM txt={"FAQS"} url="/faq" />
          {token ? (<>
            <DropdownUser/>
          
          </>):(<>
            <TOOLBAR_ITEM txt={"LOGIN"} url="/login" />
          </>)}

          <TOOLBAR_ITEM2 txt={"RUN A REPORT NOW"} url="/" />
        </div>
      </div>
      {dropdownActive && (
        <div className="block sm:hidden">
          <DROPDOWN />
        </div>
      )}
    </>
  );
}

function TOOLBAR_ITEM({ txt, url }) {
  return (
    <div className="d1_1">
      <Link to={url} className="d1_1_font hover:text-sky-500">
        {txt}
      </Link>
    </div>
  );
}

function TOOLBAR_ITEM2({ txt }) {
  return (
    <div className="GetReportBtn bg-sky-400 my-2 px-3 sm:py-3">
      <Link to="/" className="d1_2_font hover:text-black">
        {txt}
      </Link>
    </div>
  );
}

export default TOOLBAR;
