import React from "react";
import myImage from "../images/sample_report.png";
import "./sampleReport.css";

function Sample_report() {
  return (
    <>
      <div className="container mx-auto max-w-7xl">
        <div className="main mt-20 flex px-2 ">
          <div className="left_div mt-4 flex-1 pr-5 pl-5 ">
            <div className="text ">
              <h2 className="font-bold text-2xl mt-3 mb-2 ">Sample Report</h2>
              <p className="text-xl mb-5">
                The CellerCertified report example is provided on completion of
                the checking process
              </p>

              <img
                class="border-solid border-2 border-grey-500  "
                src={myImage}
                alt="/"
              />
            </div>
          </div>

          <div className="right_div flex-1 mt-6 pr-5 pl-5 ">
            <h1 className="font-bold text-2xl text-cyan-400 mb-2 ">
              Explanatory Notes
            </h1>
            <p className="mb-5 max-w-7">
              Please see the numbered roundels overlayed on the sample report
              these correspond to the notes below
            </p>

            <p>
              {" "}
              <span className="font-bold  ">1. Report ID and QR Code :</span>
              This is the unique reference code for the report. Scanning the QR
              Code will quickly enable you to validate the report on the
              CheckMEND website. Provide the Report ID if you are selling the
              article on eBay, Craigslist, Gumtree, Swappa or any ther online
              marketplaces so that a buyer can easily view the report{" "}
            </p>
            <br />

            <p>
              {" "}
              <span className="font-bold  ">2. Report Advisory :</span>
              Each check will display either a Green (clear), Orange (caution)
              or Red (warning) advisory message. It's important that you
              understand the relevant report notes before making a decision to
              buy.
            </p>
            <br />
            <p>
              {" "}
              <span className="font-bold  ">3. Search Request :</span>
              The search request consists of the item type, make, model and up
              to 3 identifiers of the article being checked (for example an IMEI
              number, serial number or another marking).
            </p>
            <br />
            <p>
              {" "}
              <span className="font-bold  ">4. Report Information :</span>
              Details of the person or organisation performing the check.
            </p>
            <br />
            <p>
              {" "}
              <span className="font-bold  ">5. Model Check Information :</span>
              In many cases we are able to validate the manufacturer and model
              of the device from the serial number you entered. This gives you
              confidence that the article is genuine and has not been altered
              since manufacture.
            </p>
            <br />
            <p>
              {" "}
              <span className="font-bold  ">6. Database Checks :</span>
              Details the database checks carried out against the user's search
              request. Each check will return either a Green (clear), Orange
              (caution) or Red (warning). Learn more about database checks.
            </p>
            <br />
            <p>
              {" "}
              <span className="font-bold  ">7. Resources :</span>
              Provides links to useful resources and support information.
            </p>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default Sample_report;
